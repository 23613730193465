import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Skeleton from '@material-ui/lab/Skeleton';

export default function Loading() {
  return (
    [...Array(2)].map((items, index) =>
      <div key={index}>
        <Grid container alignItems="center">
          <Grid item xs={12} className="pb-2">
            <Skeleton variant="text" animation="wave" width="40%" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="circle" animation="wave" width={40} height={40} />
          </Grid>
          <Grid item xs={7}>
            <Skeleton variant="text" animation="wave" width="80%" />
            <Skeleton variant="text" animation="wave" width="80%" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="circle" animation="wave" width={30} height={30} />
          </Grid>
        </Grid>
        {index < 1 && <Divider className="my-3"/>}
      </div>
    )
  )
}
