import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ReceiptTwoToneIcon from "@material-ui/icons/ReceiptTwoTone";
import { Card, CardContent } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";
import ServiceRequest from "../details/service-request";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles(theme => ({
  leftbar: {
    overflow: "visible",
    "&:before": {
      content: "''",
      height: "100%",
      width: 5,
      top: "0",
      position: "absolute",
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4
    }
  },
  red: {
    "&:before": {
      backgroundColor: theme.palette.primary.main
    }
  },
  blue: {
    "&:before": {
      backgroundColor: "#1ec0d7"
    }
  },
  black: {
    "&:before": {
      backgroundColor: "#3b3c50"
    }
  },
  purple: {
    "&:before": {
      backgroundColor: "#6f69c9"
    }
  },
  icon: {
    width: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "50%",
    background: "#dedede"
  },
  date: {
    position: "absolute",
    left: "0",
    right: "auto",
    top: "0",
    zIndex: "1",
    fontSize: "1rem",
    textAlign: "center"
  },
  num: {
    fontSize: "1.4rem",
    display: "block",
    color: "#000",
    width: "32px",
    height: "32px",
    lineHeight: "32px",
    borderRadius: "50%"
  },
  current: {
    background: "#373d49",
    color: "#fff"
  },
  noMaxWidth: {
    maxWidth: "none",
    maxHeight: "calc(100vh - 50px)",
    pointerEvents: "all",
    background: "#fff",
    overflow: "auto",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
  }
}));

export default function Schedule() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [, setActive] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
    setActive(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setActive(true);
  };

  const [open2, setOpen2] = React.useState(false);
  const [, setActive2] = React.useState(false);
  const handleTooltipClose2 = () => {
    setOpen2(false);
    setActive2(false);
  };

  const handleTooltipOpen2 = () => {
    setOpen2(true);
    setActive2(true);
  };

  const [open3, setOpen3] = React.useState(false);
  const [, setActive3] = React.useState(false);
  const handleTooltipClose3 = () => {
    setOpen3(false);
    setActive3(false);
  };

  const handleTooltipOpen3 = () => {
    setOpen3(true);
    setActive3(true);
  };

  const [open4, setOpen4] = React.useState(false);
  const [, setActive4] = React.useState(false);
  const handleTooltipClose4 = () => {
    setOpen4(false);
    setActive4(false);
  };

  const handleTooltipOpen4 = () => {
    setOpen4(true);
    setActive4(true);
  };

  return (
    <div>
      <Paper elevation={0} className="cd-month-wrap pr-2">
        <div className="cd-date-wrap position-relative pl-5 pr-2 pb-4">
          <Typography className={classes.date}>
            MON
            <span className={`${classes.num} ${classes.current}`}>20</span>
          </Typography>
          <Card
            elevation={0}
            className={`border mb-2 position-relative ${classes.leftbar} ${classes.red}`}
          >
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div className={classes.toolWrap}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="bottom"
                  classes={{ tooltip: classes.noMaxWidth }}
                  title={<ServiceRequest />}
                >
                  <div>
                    <CardActionArea onClick={handleTooltipOpen}>
                      <CardContent className="py-3 pl-3 pr-2">
                        <Grid container alignItems="center">
                          <div className="d-flex align-items-center">
                            <DescriptionTwoToneIcon className={classes.icon} />
                            <div className="p-l-10">
                              <Typography>
                                2-239725111852 | รายละเอียด/การสมัคร
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </div>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Card>
          <Card
            elevation={0}
            className={`border mb-2 position-relative ${classes.leftbar} ${classes.blue}`}
          >
            <ClickAwayListener onClickAway={handleTooltipClose2}>
              <div className={classes.toolWrap}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={handleTooltipClose2}
                  open={open2}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="bottom"
                  classes={{ tooltip: classes.noMaxWidth }}
                  title={<ServiceRequest />}
                >
                  <div>
                    <CardActionArea onClick={handleTooltipOpen2}>
                      <CardContent className="py-3 pl-3 pr-2">
                        <Grid container alignItems="center">
                          <div className="d-flex align-items-center">
                            <DescriptionTwoToneIcon className={classes.icon} />
                            <div className="p-l-10">
                              <Typography>
                                2-239725111852 | รายละเอียด/การสมัคร
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </div>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Card>
          <Card
            elevation={0}
            className={`border mb-2 position-relative ${classes.leftbar} ${classes.purple}`}
          >
            <ClickAwayListener onClickAway={handleTooltipClose3}>
              <div className={classes.toolWrap}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={handleTooltipClose3}
                  open={open3}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="bottom"
                  classes={{ tooltip: classes.noMaxWidth }}
                  title={<ServiceRequest />}
                >
                  <div>
                    <CardActionArea onClick={handleTooltipOpen3}>
                      <CardContent className="py-3 pl-3 pr-2">
                        <Grid container alignItems="center">
                          <div className="d-flex align-items-center">
                            <DescriptionTwoToneIcon className={classes.icon} />
                            <div className="p-l-10">
                              <Typography>
                                2-239725111852 | รายละเอียด/การสมัคร
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </div>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Card>
          <Card
            elevation={0}
            className={`border mb-2 position-relative ${classes.leftbar} ${classes.black}`}
          >
            <CardActionArea>
              <CardContent className="py-3 pl-3 pr-2">
                <Grid container alignItems="center">
                  <div className="d-flex align-items-center">
                    <ReceiptTwoToneIcon className={classes.icon} />
                    <div className="p-l-10">
                      <Typography>Note | รายละเอียด/การสมัคร</Typography>
                    </div>
                  </div>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>

        <div className="cd-date-wrap position-relative pl-5 pr-2">
          <Typography className={classes.date}>
            SUN
            <span className={classes.num}>19</span>
          </Typography>

          <Card
            elevation={0}
            className={`border mb-2 position-relative ${classes.leftbar} ${classes.red}`}
          >
            <ClickAwayListener onClickAway={handleTooltipClose4}>
              <div className={classes.toolWrap}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={handleTooltipClose4}
                  open={open4}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="bottom"
                  classes={{ tooltip: classes.noMaxWidth }}
                  title={<ServiceRequest />}
                >
                  <div>
                    <CardActionArea onClick={handleTooltipOpen4}>
                      <CardContent className="py-3 pl-3 pr-2">
                        <Grid container alignItems="center">
                          <div className="d-flex align-items-center">
                            <DescriptionTwoToneIcon className={classes.icon} />
                            <div className="p-l-10">
                              <Typography>
                                2-239725111852 | รายละเอียด/การสมัคร
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </div>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Card>
        </div>
      </Paper>
    </div>
  );
}
