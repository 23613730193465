import React, { useEffect } from "react";
import "./style.scss";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import BillingTabsAll from "./billing-tabs-all";
import BillingCustomer from "./billing-customer";
import BillingFullBox from "./billing-full-box";

export default function Billing() {
  const [selected, setSelected] = React.useState(1);
  const handleSelect = index => {
    setSelected(index);
  };

  const [selectTypeA, setSelectedTypeA] = React.useState(true);
  const [selectTypeB, setSelectedTypeB] = React.useState(false);
  const [selectTypeC, setSelectedTypeC] = React.useState(false);
  const [selectTypeD, setSelectedTypeD] = React.useState(false);
  const handleSelectTypeA = () => {
    setSelectedTypeA(true);
    setSelectedTypeB(false);
    setSelectedTypeC(false);
    setSelectedTypeD(false);
  };
  const handleSelectTypeB = () => {
    if (selectTypeA === true) {
      setSelectedTypeA(false);
      setSelectedTypeB(true);
    } else if (selectTypeC === true && selectTypeD === true) {
      setSelectedTypeA(true);
      setSelectedTypeB(false);
      setSelectedTypeC(false);
      setSelectedTypeD(false);
    } else if (selectTypeC === false && selectTypeD === false) {
    } else if (selectTypeB === true) {
      setSelectedTypeB(false);
    } else {
      setSelectedTypeB(true);
    }
  };
  const handleSelectTypeC = () => {
    if (selectTypeA === true) {
      setSelectedTypeA(false);
      setSelectedTypeC(true);
    } else if (selectTypeB === true && selectTypeD === true) {
      setSelectedTypeA(true);
      setSelectedTypeB(false);
      setSelectedTypeC(false);
      setSelectedTypeD(false);
    } else if (selectTypeB === false && selectTypeD === false) {
    } else if (selectTypeC === true) {
      setSelectedTypeC(false);
    } else {
      setSelectedTypeC(true);
    }
  };
  const handleSelectTypeD = () => {
    if (selectTypeA === true) {
      setSelectedTypeA(false);
      setSelectedTypeD(true);
    } else if (selectTypeB === true && selectTypeC === true) {
      setSelectedTypeA(true);
      setSelectedTypeB(false);
      setSelectedTypeC(false);
      setSelectedTypeD(false);
    } else if (selectTypeB === false && selectTypeC === false) {
    } else if (selectTypeD === true) {
      setSelectedTypeD(false);
    } else {
      setSelectedTypeD(true);
    }
  };

  useEffect(() => {
    console.log("selected is: " + selected);
    console.log("selectedA is: " + selectTypeA);
    console.log("selectedB is: " + selectTypeB);
    console.log("selectedC is: " + selectTypeC);
    console.log("selectedD is: " + selectTypeD);
  });

  return (
    <div className="billing-wrap">
      <BillingCustomer
        selected={selected}
        handleSelect={handleSelect}
        selectTypeA={selectTypeA}
        handleSelectTypeA={handleSelectTypeA}
        selectTypeB={selectTypeB}
        handleSelectTypeB={handleSelectTypeB}
        selectTypeC={selectTypeC}
        handleSelectTypeC={handleSelectTypeC}
        selectTypeD={selectTypeD}
        handleSelectTypeD={handleSelectTypeD}
      />
      <Grid container>
        <Grid item xs className={selectTypeA === true ? "" : "d-none"}>
          <BillingTabsAll />
        </Grid>
        <Grid item xs className={selectTypeA === false ? "" : "d-none"}>
          <div className="diagram-wrap full-box-wrap">
            <div className="year-wrap">
              <div className="theYear">
                <Typography variant="body1">2020</Typography>
              </div>
              <Typography variant="body2">(5 Records)</Typography>
              <Grid container className="day-wrap">
                <div className="theDate current">
                  <div className="have-payment">
                    <Typography>
                      FRI<span>24</span>
                    </Typography>
                  </div>
                </div>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeB === true ? "pr-4" : "d-none"}
                ></Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeC === true ? "pr-4" : "d-none"}
                >
                  <BillingFullBox
                    type="invoice"
                    total="1,099"
                    point="00"
                    date="24 Jan, 2020"
                    duedate="24 Jan, 2020"
                    amount="599.00"
                    payment="500.00"
                    adjust="99.00"
                    status="Closed"
                    document="/assets/mock/mock_invoice_document.png"
                  ></BillingFullBox>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeD === true ? "pr-4" : "d-none"}
                ></Grid>
              </Grid>
              <Grid container className="day-wrap">
                <div className="theDate">
                  <div className="have-payment">
                    <Typography>
                      SAT<span>18</span>
                    </Typography>
                  </div>
                </div>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeB === true ? "pr-4" : "d-none"}
                >
                  <BillingFullBox
                    type="payment"
                    total="1,099"
                    point="00"
                    date="24 Jan, 2020"
                    postdate="24 Jan, 2020"
                    channel="Customer Service"
                    amount="500.00"
                    line1="Silom"
                  ></BillingFullBox>
                  <BillingFullBox
                    type="payment"
                    total="1,099"
                    point="00"
                    date="24 Jan, 2020"
                    postdate="24 Jan, 2020"
                    channel="Customer Service"
                    amount="500.00"
                    line1="Silom"
                  ></BillingFullBox>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeC === true ? "pr-4" : "d-none"}
                ></Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeD === true ? "pr-4" : "d-none"}
                ></Grid>
              </Grid>
              <Grid container className="day-wrap">
                <div className="theDate">
                  <div className="have-payment">
                    <Typography>
                      WED<span>15</span>
                    </Typography>
                  </div>
                </div>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeB === true ? "pr-4" : "d-none"}
                ></Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeC === true ? "pr-4" : "d-none"}
                ></Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeD === true ? "pr-4" : "d-none"}
                >
                  <BillingFullBox
                    type="adjustment"
                    total="1,099"
                    point="00"
                    level="invoice"
                    description="AD3"
                    reason="002"
                    amount="99.00"
                  ></BillingFullBox>
                  <BillingFullBox
                    type="adjustment"
                    total="1,099"
                    point="00"
                    level="invoice"
                    description="AD3"
                    reason="002"
                    amount="99.00"
                  ></BillingFullBox>
                </Grid>
              </Grid>
            </div>
            <div className="year-wrap">
              <div className="theYear">
                <Typography variant="body1">2019</Typography>
              </div>
              <Typography variant="body2">(3 Records)</Typography>
              <Grid container className="day-wrap">
                <div className="theDate">
                  <div className="have-payment">
                    <Typography>
                      FRI<span>20</span>
                    </Typography>
                  </div>
                </div>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeB === true ? "pr-4" : "d-none"}
                >
                  <BillingFullBox
                    type="payment"
                    total="1,099"
                    point="00"
                    date="24 Jan, 2020"
                    postdate="24 Jan, 2020"
                    channel="Customer Service"
                    amount="500.00"
                    line1="Silom"
                  ></BillingFullBox>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeC === true ? "pr-4" : "d-none"}
                >
                  <BillingFullBox
                    type="invoice"
                    total="1,099"
                    point="00"
                    date="24 Jan, 2020"
                    duedate="24 Jan, 2020"
                    amount="599.00"
                    payment="500.00"
                    adjust="99.00"
                    status="Closed"
                    document="/assets/mock/mock_invoice_document.png"
                  ></BillingFullBox>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeD === true ? "pr-4" : "d-none"}
                >
                  <BillingFullBox
                    type="adjustment"
                    total="1,099"
                    point="00"
                    level="invoice"
                    description="AD3"
                    reason="002"
                    amount="99.00"
                  ></BillingFullBox>
                </Grid>
              </Grid>
            </div>
            <div className="year-wrap">
              <div className="theYear">
                <Typography variant="body1">2018</Typography>
              </div>
              <Typography variant="body2">(1 Records)</Typography>
              <Grid container className="day-wrap">
                <div className="theDate">
                  <div className="have-payment">
                    <Typography>
                      MON<span>25</span>
                    </Typography>
                  </div>
                </div>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeB === true ? "pr-4" : "d-none"}
                ></Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeC === true ? "pr-4" : "d-none"}
                >
                  <BillingFullBox
                    type="invoice"
                    total="1,099"
                    point="00"
                    date="24 Jan, 2020"
                    duedate="24 Jan, 2020"
                    amount="599.00"
                    payment="500.00"
                    adjust="99.00"
                    status="Closed"
                    document="/assets/mock/mock_invoice_document.png"
                  ></BillingFullBox>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  className={selectTypeD === true ? "pr-4" : "d-none"}
                ></Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
