import React from "react";
import "./style.scss";
import Activity from "../activity";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ServiceDetail from "../service-detail";

export default function ActivityBox(props) {
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
    setActive(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setActive(true);
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className="big-white-tooltips act-tooltips">
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={
              <ServiceDetail
                type={props.type ? props.type : null}
                summary={props.summary ? props.summary : null}
                detail={props.detail ? props.detail : null}
                status={props.status ? props.status : null}
                substatus={props.substatus ? props.substatus : null}
                owner={props.owner ? props.owner : null}
                ownername={props.ownername ? props.ownername : null}
                startdate={props.startdate ? props.startdate : null}
                enddate={props.enddate ? props.enddate : null}
                division={props.division ? props.division : null}
                property3={props.property3 ? props.property3 : null}
                property4={props.property4 ? props.property4 : null}
                property5={props.property5 ? props.property5 : null}
              />
            }
          >
            <div>
              <Activity
                type={props.type ? props.type : null}
                owner={props.owner ? props.owner : null}
                date={props.date ? props.date : null}
                active={active && true}
                handleTooltipOpen={handleTooltipOpen}
              ></Activity>
            </div>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}
