import React from "react";
import "./style.scss";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import JourneyBox from "./journey-box";

export default function CustomerJourney() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="customer-journey-wrap">
      <ExpansionPanel expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>2020</Typography>
          <Typography variant="body2">(7 Records)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="date-wrap">
            <Typography>
              21<span>AUG</span>
            </Typography>
            <JourneyBox
              status="success"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
          <div className="date-wrap status-success">
            {/* same status as the first grid */}
            <Typography>
              20<span>AUG</span>
            </Typography>

            <JourneyBox
              status="success"
              icon="assets/images/icon/true_online@3x.png"
              number="096-976-3222"
              color="blue"
              service="True Online"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
            <JourneyBox
              status="inprogress"
              icon="assets/images/icon/true_redcard@3x.png"
              number="096-976-3222"
              color="red"
              service="True Redcard"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
            <JourneyBox
              status="cancel"
              icon="assets/images/icon/true_tv@3x.png"
              number="096-976-3222"
              color="pink"
              service="True TV"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
          <div className="date-wrap">
            <Typography>
              19<span>AUG</span>
            </Typography>
            <JourneyBox
              status="inprogress"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
            <JourneyBox
              status="success"
              icon="assets/images/icon/true_4c@3x.png"
              number="096-976-3222"
              color="red"
              service="True 4c"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
          <div className="date-wrap status-cancel">
            <Typography>
              18<span>AUG</span>
            </Typography>
            <JourneyBox
              status="cancel"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={false}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>2019</Typography>
          <Typography variant="body2">(3 Records)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="date-wrap">
            <Typography>
              17<span>AUG</span>
            </Typography>
            <JourneyBox
              status="inprogress"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
          <div className="date-wrap status-inprogress">
            <Typography>
              16<span>AUG</span>
            </Typography>
            <JourneyBox
              status="inprogress"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
            <JourneyBox
              status="inprogress"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === "panel3"} onChange={handleChange("panel3")}      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography>2018</Typography>
          <Typography variant="body2">(3 Records)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="date-wrap">
            <Typography>
              15<span>AUG</span>
            </Typography>
            <JourneyBox
              status="cancel"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
          <div className="date-wrap">
            <Typography>
              14<span>AUG</span>
            </Typography>
            <JourneyBox
              status="cancel"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
            <JourneyBox
              status="cancel"
              icon="assets/images/icon/true_move@3x.png"
              number="096-976-3222"
              color="yellow"
              service="TrueMove H"
              active={true}
              time="15.00.20"
              detail="Event Add | Request Channel UUSD" />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
