import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default function Information() {
    return (
        <div className="pb-4 asset-information">
            <Typography variant="h6" className="pt-4 pb-2">
                Information
            </Typography>
            <Grid container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">Effective Date:</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">22 Apr, 2019 | 18:30</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">Special Note at Asset:</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">0.00</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">Asset Password:</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">00001234</Typography>
                </Grid>
            </Grid>
        </div>
    );
}