import React from "react";
import "./style.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';

export default function ServiceDetail(props) {
  return (
    <div className="campaign-detail">
      <Card square elevation={0}>
        <CardContent className="p-0">
          {/* <Typography
            className="font-weight-bold mb-2"
            variant="subtitle1"
            component="p"
          >
            {props.type ? props.type : null}
          </Typography>

          <Grid container item className="flex-column">
            <Typography variant="subtitle1" component="p">
              Detail
            </Typography>
            <Typography variant="caption" component="p">
              {props.detail ? props.detail : "No Detail"}
            </Typography>
          </Grid>
          <Divider className="mt-2 mb-2" />
          <Grid className="flex-column" container item>
            <Grid container className={props.owner ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className="op-0-6"
                >
                  Owner:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" component="p">
                  {props.owner ? props.owner : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.date ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className="op-0-6"
                >
                  Date:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" component="p">
                  {props.date ? props.date : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.property3 ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className="op-0-6"
                >
                  Property-3:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" component="p">
                  {props.property3 ? props.property3 : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.property4 ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className="op-0-6"
                >
                  Property-4:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" component="p">
                  {props.property4 ? props.property4 : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.property5 ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className="op-0-6"
                >
                  Property-5:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" component="p">
                  {props.property5 ? props.property5 : null}
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          <div className="pt-0 pb-2 px-2">
            <Typography variant="h6" className="text-center title-popover bg-dark text-white py-2 mx-auto">
              Activities
            </Typography>
            <div className="py-3">
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Type:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.type ? props.type : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Summary:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.summary ? props.summary : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Detail:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.detail ? props.detail : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Status:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.status ? props.status : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Sub Status:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.substatus ? props.substatus : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Planned Start:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.startdate ? props.startdate : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Planned End:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.enddate ? props.enddate : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Owner:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.owner ? props.owner : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Owner Name:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.ownername ? props.ownername : "-"}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Division:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2">{props.division ? props.division : "-"}</Typography>
                </Grid>
              </Grid>
            </div>
            <Button variant="outlined"><EditIcon className="mr-2" />Edit</Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
