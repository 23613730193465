import React from "react";
import './style.scss'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Menubar from '../../component/menubar'
import Content from './content'
import Note from '../../component/note'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import _ from 'lodash';
// import { connect } from 'react-redux'
// import { TABS } from './../../redux/reducers/actions'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: 47,
    },
    tabs: {
        cursor: 'default'
    },
    iconBtn: {
        padding: theme.spacing(.5),
        cursor: "pointer",
    },
    avatar: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        // border: '2px solid #FFF'
    },
    closeicon: {
        color: '#c6c6cc',
    }
}));

export default function Dashboard(props) {
    const classes = useStyles();
    const [activeTabs, setActiveTabs] = React.useState(0);
    const [userTabs, setUserTabs] = React.useState([
        {
            uid: '0832202929'
        }
    ]);

    const handleChange = (event, newValue) => {
        if (event.target.id !== "close" && event.target.nodeName !== "path") {
            setActiveTabs(newValue)
        }
    }

    const handleClose = (pos, uid) => {
        const filteredItems = userTabs.filter(items => items.uid !== uid)
        if (pos <= activeTabs) {
            setActiveTabs(activeTabs === 0 ? 0 : activeTabs - 1)
        }
        setUserTabs(filteredItems)
    }

    const handleAddTab = (uid) => e => {
        window.scrollTo(0, 0)
        const filteredItems = userTabs.filter(items => items.uid === uid)
        const condition = items => items.uid === uid;
        const active = userTabs.findIndex(condition)
        const totalUserTabs = userTabs.length

        if (_.isEmpty(filteredItems)) {
            setUserTabs([...userTabs, { uid: uid }])
            setActiveTabs(totalUserTabs)
        } else {
            setActiveTabs(active)
        }
    }

    return (
        <div>
            <Menubar {...props} />
            <Container maxWidth={false} disableGutters className={classes.container}>
                <AppBar className="headtabs" position="static" color="default">
                    <Tabs
                        value={activeTabs}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        className="mx-2 mt-2"
                    >
                        {userTabs.map((items, index) =>
                            <Tab key={index}
                                label={
                                    <div className="d-flex align-items-center">
                                        <div><img className="img-fluid" src="/assets/images/icon/true_move@3x.png" width="17px" alt="" /></div>
                                        <div className="pl-2">{items.uid}</div>
                                        <div>
                                            {userTabs.length > 1 ?
                                                <div id="close" className={classes.iconBtn} onClick={() => handleClose(index, items.uid)}>
                                                    <CloseOutlinedIcon id="close" fontSize="small" className={classes.closeicon} />
                                                </div>
                                                :
                                                <div id="close" className={classes.iconBtn}>
                                                    <CloseOutlinedIcon id="close" fontSize="small" className={classes.closeicon} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {...a11yProps(index)}
                            />
                        )}
                    </Tabs>
                </AppBar>
                {userTabs.map((items, index) =>
                    <TabPanel key={index} value={activeTabs} index={index}>
                        <Paper elevation={0} square className="mx-2">
                            <div className="d-flex justify-content-between p-2">
                                <div className="d-inline-flex align-items-center customer p-1">
                                    <Avatar alt="Remy Sharp" src="/assets/images/icon/user_vip@3x.png" className={classes.avatar} />
                                    <div className="px-2">คุณจิตดี คุณธรรม</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="mr-2">
                                        <img className="img-fluid" src="/assets/images/icon/bill_red@3x.png" width="50px" alt="" />
                                    </div>
                                    <div>
                                        <img className="img-fluid" src="/assets/images/icon/status_active@3x.png" width="65px" alt="" />
                                    </div>
                                </div>
                            </div>
                        </Paper>
                        <Content uid={items.uid} handleAddTab={handleAddTab} />
                    </TabPanel>
                )}
            </Container>
            <Note />
        </div>
    );
}

// const mapStatetoProps = (state) => {
//     return { Tabs: state.Tabs }
// }

// const mapDispatchtoProps = (dispatch) => {
//     return {
//         dispatch: dispatch,
//     }
// }

// export default connect(mapStatetoProps, mapDispatchtoProps)(Tabs)