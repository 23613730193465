import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export default function Loading() {
  return (
    <div>
      <Card variant="outlined">
        <CardContent className="py-3">
          <Skeleton variant="text" animation="wave" width="60%" height={25} />
          <Skeleton variant="text" animation="wave" width="80%" height={25} />
          <Skeleton variant="text" animation="wave" width="40%" />
          <Skeleton variant="text" animation="wave" width="60%" />
          <Skeleton variant="text" animation="wave" width="70%" />
        </CardContent>
      </Card>
      <Grid container alignItems="center" direction="column" className="my-4">
        <Skeleton variant="text" animation="wave" width="60%" height={25} />
        <Skeleton variant="text" animation="wave" width="20%" height={40} />
        <Skeleton variant="text" animation="wave" width="100%" height={70} />
      </Grid>
      <Divider />
      <Grid container className="my-4">
        <Skeleton variant="text" animation="wave" width="40%" />
      </Grid>
      <Grid container className="mt-4 mb-2">
        <Skeleton variant="rect" animation="wave" width="100%" height={110} />
      </Grid>
      <Grid container justify="center" className="mb-4">
        <Skeleton variant="text" animation="wave" width="30%" />
      </Grid>
      <Divider />
      <Grid container className="my-3">
        <Skeleton variant="text" animation="wave" width="60%" />
      </Grid>
      <Grid container justify="space-between" className="my-4">
        <Grid container item xs={4} alignItems="center" direction="column">
          <Skeleton variant="rect" animation="wave" width={60} height={60} />
          <Skeleton variant="text" animation="wave" width="50%" />
          <Skeleton variant="text" animation="wave" width="80%" />
          <Skeleton variant="text" animation="wave" width="50%" />
        </Grid>
        <Grid container item xs={4} alignItems="center" direction="column">
          <Skeleton variant="rect" animation="wave" width={60} height={60} />
          <Skeleton variant="text" animation="wave" width="50%" />
          <Skeleton variant="text" animation="wave" width="80%" />
          <Skeleton variant="text" animation="wave" width="50%" />
        </Grid>
        <Grid container item xs={4} alignItems="center" direction="column">
          <Skeleton variant="rect" animation="wave" width={60} height={60} />
          <Skeleton variant="text" animation="wave" width="50%" />
          <Skeleton variant="text" animation="wave" width="80%" />
          <Skeleton variant="text" animation="wave" width="50%" />
        </Grid>
      </Grid>
      <Grid container justify="center" className="mb-4">
        <Skeleton variant="text" animation="wave" width="10%" />
      </Grid>
      <Divider />
      <Grid container className="mt-4 mb-2">
        <Skeleton variant="text" animation="wave" width="40%" />
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <Skeleton variant="text" animation="wave" width="55%" />
        </Grid>
        <Grid item xs={7}>
          <Skeleton variant="text" animation="wave" width="50%" />
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant="text" animation="wave" width="52%" />
        </Grid>
        <Grid item xs={7}>
          <Skeleton variant="text" animation="wave" width="50%" />
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant="text" animation="wave" width="57%" />
        </Grid>
        <Grid item xs={7}>
          <Skeleton variant="text" animation="wave" width="40%" />
        </Grid>
      </Grid>
    </div>
  );
}
