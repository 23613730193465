import React from 'react'
import Grid from "@material-ui/core/Grid";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  contentCenter: {
    padding: theme.spacing(2, 0),
  },
  row: {
    padding: theme.spacing(2),
    borderRight: '1px solid #dee2e6',
    [theme.breakpoints.down('md')]: {
      borderRight: 0,
    },
    '&:last-child': {
      borderRight: 0,
    },
  },
  currentPricePlan: {
    backgroundColor: '#373d49',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    textAlign: 'center',
    color: '#FFF',
    paddingTop: 8,
    paddingBottom: 8,
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div>
      <Grid container className="p-3">
        <Skeleton variant="rect" animation="wave" width="20%" className="mt-3" />
        <Grid container className={classes.contentCenter}>
          {[...Array(3)].map((items, index) =>
            <Grid item xs={12} lg={4} key={index} className={classes.row}>
              <div className="d-flex pb-3">
                <div>
                  <Skeleton variant="circle" animation="wave" width={40} height={40} />
                </div>
                <div className="pl-3">
                  <Skeleton variant="text" animation="wave" width={80} height={19} />
                  <Skeleton variant="text" animation="wave" width={40} height={31} />
                  <Skeleton variant="text" animation="wave" width={113} height={19} />
                </div>
              </div>
              <div className="px-4">
                <Skeleton variant="text" animation="wave" width="100%" />
                <Skeleton variant="text" animation="wave" width="100%" />
                <Skeleton variant="text" animation="wave" width="100%" />
              </div>
            </Grid>
          )}
        </Grid >

        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <div className={classes.currentPricePlan}>
              Current Price Plan
          </div>
          </Grid>
          <Grid item xs={4} className="p-3 border-right">
            <Grid container direction="row" alignItems="center" justify="center">
              <Skeleton variant="text" animation="wave" width={60} height={26} />
            </Grid>
          </Grid>
          <Grid container item xs={4} direction="column" alignItems="center" justify="center" className="p-3 border-right">
            <Skeleton variant="text" animation="wave" width={60} height={26} />
            <Skeleton variant="text" animation="wave" width={70} />
          </Grid>
          <Grid item xs={4} className="p-3">
            <Grid container alignItems="center" justify="center">
              <Skeleton variant="text" animation="wave" width={20} height={26} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}