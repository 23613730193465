import React from "react";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import Typography from "@material-ui/core/Typography";

export default function Active() {
    return (
        <div>
            <Typography
                className="font-weight-bold pb-3"
                variant="body1"
                component="p"
            >
                Unbilled Summary
            </Typography>
            <Grid container className="pb-2">
                <Grid item xs={4}>
                    <Typography
                        className="font-weight-bold"
                        variant="body2"
                        component="p"
                    >
                        Voice
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        Usage
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        Price
                </Typography>
                </Grid>
            </Grid>
            <Grid container className="pb-2">
                <Grid item xs={4}>
                    <Typography variant="body2" component="p">
                        In
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        120
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        360
                </Typography>
                </Grid>
            </Grid>
            <Grid container className="pb-2">
                <Grid item xs={4}>
                    <Typography variant="body2" component="p">
                        Out
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        10
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        50
                </Typography>
                </Grid>
            </Grid>
            <Grid container className="pb-2 pt-3">
                <Grid item xs={4}>
                    <Typography
                        className="font-weight-bold"
                        variant="body2"
                        component="p"
                    >
                        Data
                </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        Fair Speed: 123 kpbs
                </Typography>
                </Grid>
            </Grid>
            <Grid container className="pb-2">
                <Grid item xs={4}>
                    <Typography variant="body2" component="p">
                        In
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        20 GB
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        200
                </Typography>
                </Grid>
            </Grid>
            <Grid container className="pb-2">
                <Grid item xs={4}>
                    <Typography variant="body2" component="p">
                        SMS
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        -
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        -
                </Typography>
                </Grid>
            </Grid>
            <Grid container className="pb-2">
                <Grid item xs={4}>
                    <Typography variant="body2" component="p">
                        Wifi
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        -
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="text-right"
                        variant="body2"
                        component="p"
                    >
                        -
                </Typography>
                </Grid>
            </Grid>
            <Grid container className="pb-2 pt-2">
                <Grid item xs={8} className="align-bottom">
                    <Typography
                        className="font-weight-bold d-inline-block"
                        variant="body2"
                        component="p"
                    >
                        Total
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="font-weight-bold text-right"
                        variant="body1"
                        component="p"
                    >
                        610
                </Typography>
                </Grid>
            </Grid>
        </div >
    );
}