import React, { Suspense } from "react";
import "./style.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import Headbar from "../headbar";
import Loading from '../loading/knowledge'

const LazyKnowledge = React.lazy(() =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(
      import("./index").then(module => ({ default: module.Knowledge }))
    ), 5000)
  )
);

export function Knowledge() {
  return <div className="p-t-100 p-b-100 text-center">DATA</div>
}

export default function Index() {
  return (
    <Card square>
      <Headbar
        icon={<ImportContactsIcon />}
        title="Knowledge Base"
        button />
      <CardContent>
        <Suspense fallback={<Loading />}>
          <LazyKnowledge />
        </Suspense>
      </CardContent>
    </Card>
  )
}
