import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import muiTextField from '@material-ui/core/TextField';
import Headbar from "../../headbar";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1, 1, 0, 1)
    }
}));

const TextField = withStyles(theme => ({
    root: {
        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 6.5px) scale(0.75)',
        },
        '& .MuiInput-underline:before': {
            content: 'unset !important',
        }
    }
}))(muiTextField);



export default function MyField(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card square>
                <Headbar icon={props.icon} title={props.headTitle} />
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Product Type"
                                className={classes.nonLine}
                                defaultValue="Fiber to home"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Subscriber"

                                defaultValue="น.ส. ชัชฏา พัณเสท"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Most Usage"

                                defaultValue="นนทบุรี"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Preferred Language"

                                defaultValue="ไทย"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Face Recognition"

                                defaultValue="test"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Status"

                                defaultValue="Active"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Disconnect Date"
                                defaultValue="test"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Disconnect Reason"
                                defaultValue="test"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Disconnect Sub Reason"
                                defaultValue="test"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Install Date"
                                defaultValue="14/52019 14:22:32"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Effective Date"
                                defaultValue="14/52019 14:22:32"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Original Effective Date"
                                defaultValue="14/52019 14:22:32"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Expiry Date"
                                defaultValue="14/52019 14:22:32"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Asset Lifetime"
                                defaultValue="3 year, 9 months, 9 day"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className="py-0">
                            <TextField
                                label="Spacial Note"
                                multiline
                                rowsMax="4"
                                defaultValue="test test"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}
