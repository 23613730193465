import React from "react";
import "../style.scss";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CardMedia from "@material-ui/core/CardMedia";

export default function JourneyBox(props) {
  return (
    <div className={props.status ? "status-" + props.status : "status-inprogress"}    >
      <Grid container>
        <Grid container item xs className="flex-0-0-a pr-2">
          <CardMedia
            className="status-icon"
            image={props.icon ? props.icon : ""}
            title="Service Icon"
          />
        </Grid>
        <Grid container item xs>
          <Grid container>
            <Grid container item xs className="flex-column">
              <Typography variant="body1">
                {props.number ? props.number : "000-000-0000"}
              </Typography>
              <Typography
                variant="body2"
                className={
                  props.active
                    ? "subtitle active font-" + props.color
                    : "subtitle inactive font-" + props.color
                }
              >
                {props.service ? props.service : "Service Name"}
              </Typography>
            </Grid>
            <Grid container item xs className="flex-0-0-a align-items-center">
              <Typography variant="body2" className="time">
                <AccessTimeIcon className="mr-2" />
                {props.time ? props.time : "00.00.00"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="caption">
              {props.detail ? props.detail : "No Detail"}
            </Typography>
          </Grid>
        </Grid>
        <div className="link-line"></div>
      </Grid>
    </div>
  );
}
