import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Headbar from "./../headbar";
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import Usage3Months from "./usage3months";
import Usage from "./usage";
import BalanceSummary from "./balancesummary";
import LoadingUsage3Months from './../loading/usage3months'
import LoadingUsage from './../loading/usage'
import LoadingBalancesummary from './../loading/balancesummary'

const LazyTabs1 = React.lazy(() =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(
      import("./index").then(module => ({ default: module.Tabs1 }))
    ), 3000)
  )
);

const LazyTabs2 = React.lazy(() =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(
      import("./index").then(module => ({ default: module.Tabs2 }))
    ), 3000)
  )
);

const LazyTabs3 = React.lazy(() =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(
      import("./index").then(module => ({ default: module.Tabs3 }))
    ), 3000)
  )
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

export function Tabs1() {
  return (
    <Usage3Months />
  );
}

export function Tabs2() {
  return (
    <Usage />
  );
}

export function Tabs3() {
  return (
    <BalanceSummary />
  );
}

export default function Index() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Card square>
        <Headbar icon={<InboxOutlinedIcon />} title="Summary Box" />
        <CardContent>
          <AppBar className="tabs-header" position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Usage 3 Months" {...a11yProps(0)} />
              <Tab label="Usage" {...a11yProps(1)} />
              <Tab label="Balance Summary" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Suspense fallback={<LoadingUsage3Months />}>
              <LazyTabs1 />
            </Suspense>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Suspense fallback={<LoadingUsage />}>
              <LazyTabs2 />
            </Suspense>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Suspense fallback={<LoadingBalancesummary />}>
              <LazyTabs3 />
            </Suspense>
          </TabPanel>
        </CardContent>
      </Card>
    </div >
  )
}
