import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import './style.scss'
import IconButton from '@material-ui/core/IconButton';
import MenuOutlined from '@material-ui/icons/MenuOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Badge from '@material-ui/core/Badge';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    bgNavbar: {
        backgroundColor: theme.palette.darkblue.main
    },
    textColor: {
        color: '#747688',
    },
    icon: {
        color: '#747688',
        fontSize: 25
    },
    iconActive: {
        color: theme.palette.primary.main
    }
}));

const ExpansionPanel = withStyles(theme => ({
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: '#747688',
        '& .MuiTypography-root': {
            color: '#747688',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        padding: 0,
        marginBottom: -1,
        minHeight: 0,
        '&$expanded': {
            minHeight: 0,
        },
        '& .MuiSvgIcon-root': {
            color: '#747688'
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(0, 2, 2, 4),
    },
}))(MuiExpansionPanelDetails);

export default function Layout(props) {
    const classes = useStyles();
    const [slidebar, setSlidebar] = React.useState(false);
    const [menuActive] = React.useState(props.match.path);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleSlidebar = () => {
        setSlidebar(prev => !prev);
    };

    const handleClose = () => {
        setSlidebar(false);
    };

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const classActive = (path) => {
        return menuActive === path ? 'text-white' : ''
    }

    return (
        <div>
            <div className={`navbar-crm d-flex flex-column justify-content-between ${classes.bgNavbar}`}>
                <div className="d-flex flex-column align-items-center">
                    <Link to="#" onClick={handleSlidebar}>
                        <IconButton>
                            <MenuOutlined className={classes.icon} />
                        </IconButton>
                    </Link>
                    <Link to="./">
                        <IconButton>
                            <HomeOutlinedIcon className={[classes.icon, menuActive === "/" ? classes.iconActive : ''].join(' ')} />
                        </IconButton>
                    </Link>
                    <Link to="#">
                        <IconButton>
                            <Badge badgeContent={5} color="primary">
                                <NotificationsOutlinedIcon className={classes.icon} />
                            </Badge>
                        </IconButton>
                    </Link>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <IconButton >
                        <InfoOutlinedIcon className={classes.icon} />
                    </IconButton>
                    <IconButton>
                        <SettingsOutlinedIcon className={classes.icon} />
                    </IconButton>
                    <IconButton>
                        <Avatar src="/assets/images/icon/user_vip@3x.png" className={classes.avatar} />
                    </IconButton>
                </div>
            </div>
            <div className={`navbar-slide trans-0-1 ${classes.bgNavbar} ${slidebar && 'navbar-slide-active'}`}>
                <div className={`py-2 px-4 trans-0-1 ${!slidebar && 'd-none'}`}>
                    <div className="pb-5">
                        <img className="img-fluid" src="/assets/images/icon/crm_logo@3x.png" width="150px" alt="" />
                    </div>
                    <Typography noWrap className={`pb-2 ${classes.textColor}`}>MENUS</Typography>
                    <div className="w-100">
                        <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <SettingsOutlinedIcon className="mr-3" />
                                <Typography noWrap><strong>Menu A</strong></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <ul>
                                    <li className={`pb-3 ${classActive('/')}`}>
                                        <Link to="./">
                                            <Typography noWrap className={classActive('/')}>Home</Typography>
                                        </Link>
                                    </li>
                                    <li className={`pb-3 ${classActive('/dashboard')}`}>
                                        <Link to="./dashboard">
                                            <Typography noWrap className={classActive('/dashboard')}>Dashboard</Typography>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <Typography noWrap>SubmenuActive 01</Typography>
                                        </Link>
                                    </li>
                                </ul>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <SettingsOutlinedIcon className="mr-3" />
                                <Typography noWrap><strong>Menu B</strong></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                <ul>
                                    <li className="pb-3"><Typography noWrap>Submenu_01</Typography></li>
                                    <li className="pb-3"><Typography noWrap>Submenu_02</Typography></li>
                                    <li><Typography noWrap>Submenu_03</Typography></li>
                                </ul>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <SettingsOutlinedIcon className="mr-3" />
                                <Typography noWrap><strong>Menu C</strong></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                <ul>
                                    <li className="pb-3"><Typography noWrap>Submenu_01</Typography></li>
                                    <li className="pb-3"><Typography noWrap>Submenu_02</Typography></li>
                                    <li><Typography noWrap>Submenu_03</Typography></li>
                                </ul>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div>
            </div>
            {slidebar && <div className="navbar-overlay" onClick={handleClose}></div>}
        </div >
    )
}