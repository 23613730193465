import React, { Suspense } from "react";
import Card from "@material-ui/core/Card";
import Headbar from "../headbar";
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import muiTooltip from "@material-ui/core/Tooltip";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import _ from 'lodash'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import muiTabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomerJourney from "./customer-journey";
import Billing from "./billing";
import AssetDetail from "./asset-detail";
import Campaign from "./campaign-history";
import CommunicationHistory from "./communication-history";
import ServiceRequest from "./service-request";
import StatusHistory from "./status-history";
import Orders from './orders'
import FinalCharge from './final-charge'
import LoadingCustomerJourney from '../loading/customer-journey'
import LoadingBilling from '../loading/billing'
import LoadingAssetDetail from '../loading/asset-detail'
import LoadingCampaign from '../loading/campaign-history'
import LoadingCommunicationHistory from '../loading/communication-history'
import LoadingServiceRequest from '../loading/service-request'
import LoadingStatusHistory from '../loading/status-history'
import LoadingOrders from '../loading/orders'

const LazyCustomerJourney = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyCustomerJourney }))
        ), 500)
    )
);
export function MyCustomerJourney() {
    return <CustomerJourney />
}

const LazyAssetDetail = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyAssetDetail }))
        ), 500)
    )
);
export function MyAssetDetail() {
    return <AssetDetail />
}

const LazyBilling = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyBilling }))
        ), 500)
    )
);
export function MyBilling() {
    return <Billing />
}

const LazyCommunicationHistory = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyCommunicationHistory }))
        ), 500)
    )
);
export function MyCommunicationHistory() {
    return <CommunicationHistory />
}

const LazyCampaign = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyCampaign }))
        ), 500)
    )
);
export function MyCampaign() {
    return <Campaign />
}

const LazyServiceRequest = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyServiceRequest }))
        ), 500)
    )
);
export function MyServiceRequest() {
    return <ServiceRequest />
}

const LazyOrders = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyOrders }))
        ), 500)
    )
);
export function MyOrders() {
    return <Orders />
}

const LazyStatusHistory = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyStatusHistory }))
        ), 500)
    )
);
export function MyStatusHistory() {
    return <StatusHistory />
}

const LazyFinalCharge = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyFinalCharge }))
        ), 500)
    )
);
export function MyFinalCharge() {
    return <FinalCharge />
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    container: {
        height: 530,
        overflowY: 'auto',
        backgroundColor: '#f2f3f8',
    },
}));

const Tabs = withStyles(theme => ({
    scrollButtons: {
        '& .MuiSvgIcon-root': {
            padding: 5,
            borderRadius: '50%',
            boxShadow: theme.shadows[1],
            width: '1.7em',
            height: '1.7em',
        }
    },
}))(muiTabs);

const Tooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary
    },
}))(muiTooltip);

function TabPanel(props) {
    const classes = useStyles()
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index &&
                <Box p={2}>
                    <div className={classes.container}>{children}</div>
                </Box>
            }
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

function Label(index, items, submenuOpen, handleSubmenuOpen, handleSubmenuClose) {
    const classes = useStyles();
    const { name, submenu } = items

    return (
        <div>
            {!_.isEmpty(submenu) ?
                <Tooltip
                    title={
                        <List component="nav">
                            {submenu.map((items, index) => {
                                return <ListItem key={index} button className={classes.listitem}>
                                    <ListItemText primary={items.listsub} />
                                </ListItem>
                            })}
                        </List>
                    }
                    className={classes.tooltip}
                    interactive
                    open={submenuOpen === index ? true : false}
                    onClose={handleSubmenuClose}
                    onOpen={() => handleSubmenuOpen(index)}
                >
                    <div className="d-flex">
                        {name}
                        <KeyboardArrowDownIcon />
                    </div>
                </Tooltip>
                :
                name
            }
        </div >
    );
}

export default function Index() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [submenuOpen, setSubmenuOpen] = React.useState(false)

    const [menu] = React.useState([
        {
            name: 'Customer Journey',
            submenu: false
        },
        {
            name: 'Asset Detail',
            submenu: [
                { listsub: 'Create New Post' },
                { listsub: 'Manage Orders' },
                { listsub: 'Customer Feedbacks' },
                { listsub: 'Register Member' }
            ]
        },
        {
            name: 'Billing',
            submenu: false
        },
        {
            name: 'Communication History',
            submenu: false
        },
        {
            name: 'Campaign',
            submenu: [
                { listsub: 'Create New Post' },
                { listsub: 'Manage Orders' },
                { listsub: 'Customer Feedbacks' },
                { listsub: 'Register Member' }
            ]
        },
        {
            name: 'Service Request',
            submenu: false
        },
        {
            name: 'Status History',
            submenu: false
        },
        {
            name: 'Order',
            submenu: false
        },
        {
            name: 'Final Charge',
            submenu: false
        },
    ])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubmenuOpen(false)
    };

    const handleSubmenuOpen = select => {
        setSubmenuOpen(select)
    }

    const handleSubmenuClose = () => {
        setSubmenuOpen(false);
    };

    return (
        <div className={classes.root}>
            <Card square>
                <Headbar icon={<LocalMallOutlinedIcon />} title="Detail" />
                <AppBar className="tabs-header-white" position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {menu.map((items, index) =>
                            <Tab
                                key={index}
                                label={Label(index, items, submenuOpen, handleSubmenuOpen, handleSubmenuClose)}
                                {...a11yProps(index)} />
                        )}
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Suspense fallback={<LoadingCustomerJourney />}>
                        <LazyCustomerJourney />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Suspense fallback={<LoadingAssetDetail />}>
                        <LazyAssetDetail />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Suspense fallback={<LoadingBilling />}>
                        <LazyBilling />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Suspense fallback={<LoadingCommunicationHistory />}>
                        <LazyCommunicationHistory />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Suspense fallback={<LoadingCampaign />}>
                        <LazyCampaign />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <Suspense fallback={<LoadingServiceRequest />}>
                        <LazyServiceRequest />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <Suspense fallback={<LoadingStatusHistory />}>
                        <LazyStatusHistory />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <Suspense fallback={<LoadingOrders />}>
                        <LazyOrders />
                    </Suspense>
                </TabPanel>
                <TabPanel value={value} index={8}>
                    <Suspense fallback={<LoadingOrders />}>
                        <LazyFinalCharge />
                    </Suspense>
                </TabPanel>
            </Card>
        </div>
    )
}
