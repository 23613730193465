import React from "react";
import "./style.scss";
// import PendingTaskDetail from "./pendingtaskdetail.js";
import ServiceRequest from "./../details/service-request";
import Card from "@material-ui/core/Card";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default function PendingTask() {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Card square className="pending-task" elevation={0}>

        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              placement="left-start"
              title={<ServiceRequest />}
            >
              <CardActionArea>
                <CardContent onClick={handleTooltipOpen} className="pb-3">
                  <Grid container>
                    <Grid container item className="flex-column" xs={10}>
                      <Typography
                        className="text-white font-weight-bold"
                        variant="subtitle1"
                        component="p"
                      >
                        Pending Task
                    </Typography>
                      <Typography
                        className="text-white"
                        variant="caption"
                        component="p"
                      >
                        SR+Activity
                    </Typography>
                    </Grid>
                    <Grid container item xs={2} className="justify-content-end">
                      <Typography
                        className="text-white mt-auto mr-0 mb-auto ml-auto"
                        variant="h4"
                        component="p"
                      >
                        25
                    </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </Card>
    </div >
  );
}
