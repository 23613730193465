import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 1, 0, 1)
  }
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Card square className="p-3">
          <Grid container alignItems="center">
            <Skeleton variant="circle" animation="wave" width={30} height={30} />
            <Skeleton variant="text" animation="wave" width="20%" height={25} className="ml-3" />
          </Grid>
        </Card>
        <Card square className="p-3">
          <Grid container alignItems="center">
            {[...Array(15)].map((items, index) =>
              <Grid key={index} item xs={4}>
                <Skeleton variant="text" animation="wave" width="80%" height={40} />
              </Grid>
            )}
          </Grid>
        </Card>
      </div>
      <div className={classes.root}>
        <Card square className="p-3">
          <Grid container alignItems="center">
            <Skeleton variant="circle" animation="wave" width={30} height={30} />
            <Skeleton variant="text" animation="wave" width="20%" height={25} className="ml-3" />
          </Grid>
          <Grid container justify="flex-end">
            <Skeleton variant="text" animation="wave" width="40%" height={40} />
          </Grid>
          <Grid container alignItems="center">
            {[...Array(5)].map((items, index) =>
              <Skeleton variant="rect" animation="wave" width="100%" height={40} className="mb-1" />
            )}
          </Grid>
        </Card>
      </div>
    </div>
  )
}
