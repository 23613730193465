import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Skeleton from '@material-ui/lab/Skeleton';

export default function Loading() {
  return (
    [...Array(3)].map((items, index) =>
      <Card key={index} square elevation={0}>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Skeleton variant="circle" animation="wave" width={30} height={30} />
            </Grid>
            <Grid item xs={9}>
              <Skeleton variant="text" animation="wave" width="100%" />
              <Skeleton variant="text" animation="wave" width="100%" />
            </Grid>
          </Grid>
        </CardContent>
        {index < 2 && <Divider />}
      </Card>
    )
  )
}
