import React from "react";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import Typography from "@material-ui/core/Typography";

export default function InActive() {
    return (
        <div>
            <Typography
                className="font-weight-bold pb-3"
                variant="body1"
                component="p"
            >
                Detail
            </Typography>
            <Grid className="pb-2" container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">
                        Status:
                </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">Active</Typography>
                </Grid>
            </Grid>
            <Grid className="pb-2" container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">
                        Product Type:
                </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">Discount</Typography>
                </Grid>
            </Grid>
            <Grid className="pb-2" container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">
                        SOC/Offer Name:
                </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">
                        GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day
                </Typography>
                </Grid>
            </Grid>
            <Grid className="pb-2" container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">
                        Install Date:
                </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">31/01/2020 14:22:30</Typography>
                </Grid>
            </Grid>
            <Grid className="pb-2" container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">
                        Effective Date:
                </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">31/01/2020 14:22:30</Typography>
                </Grid>
            </Grid>
            <Grid className="pb-2" container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">
                        Expiry Date:
                </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">31/01/2020 14:22:30</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={5}>
                    <Typography variant="body2" color="textSecondary">
                        Service ID:
                </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">23523543059093450</Typography>
                </Grid>
            </Grid>
        </div >
    );
}