import React from "react";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import Typography from "@material-ui/core/Typography";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";

export default function CVGContent() {
  const data = [
    { name: "Jan", THB: 499 },
    { name: "Feb", THB: 679 },
    { name: "Mar", THB: 899 },
    { name: "Apr", THB: 700 },
    { name: "May", THB: 1200 },
    { name: "June", THB: 1527 }
  ];

  return (
    <div>
      <LineChart width={600} height={200} data={data}>
        <CartesianGrid stroke="#f2f3f8" />
        <Line type="monotone" dataKey="THB" stroke="#F4516C" />
        <XAxis dataKey="name" />
        <YAxis />
      </LineChart>
      <Typography className="text-center mb-2" variant="h6" component="p">
        Outstanding Balance:
        <span className="font-weight-bold pl-3">1,527.23</span>
      </Typography>
      <Grid container className="pb-2">
        <Grid item xs className="d-flex align-items-center flex-column pr-3">
          <Typography
            className="font-weight-bold text-center w-100 pb-1 text-main"
            variant="body1"
            component="h6"
          >
            BAN TMH
          </Typography>
          <Grid container>
            <Grid item xs className="overflow-hidden">
              <Typography className="op-0-6" variant="body1" component="p">
                Outstanding Balance:
              </Typography>
            </Grid>
            <Grid item xs className="flex-0-0-a">
              <Typography className="text-right" variant="body1" component="p">
                399.17
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs className="overflow-hidden">
              <Typography className="op-0-6" variant="body1" component="p">
                Current Bill:
              </Typography>
            </Grid>
            <Grid item xs className="flex-0-0-a">
              <Typography className="text-right" variant="body1" component="p">
                399.17
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs
          className="d-flex align-items-center flex-column pl-3 pr-3 border-left border-right"
        >
          <Typography
            className="font-weight-bold text-center w-100 pb-1 text-main"
            variant="body1"
            component="h6"
          >
            BAN TOL
          </Typography>
          <Grid container>
            <Grid item xs className="overflow-hidden">
              <Typography className="op-0-6" variant="body1" component="p">
                Outstanding Balance:
              </Typography>
            </Grid>
            <Grid item xs className="flex-0-0-a">
              <Typography className="text-right" variant="body1" component="p">
                1,000.00
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs className="overflow-hidden">
              <Typography className="op-0-6" variant="body1" component="p">
                Current Bill:
              </Typography>
            </Grid>
            <Grid item xs className="flex-0-0-a">
              <Typography className="text-right" variant="body1" component="p">
                700.00
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs className="d-flex align-items-center flex-column pl-3">
          <Typography
            className="font-weight-bold text-center w-100 pb-1 text-main"
            variant="body1"
            component="h6"
          >
            BAN TMH
          </Typography>
          <Grid container>
            <Grid item xs className="overflow-hidden">
              <Typography className="op-0-6" variant="body1" component="p">
                Outstanding Balance:
              </Typography>
            </Grid>
            <Grid item xs className="flex-0-0-a">
              <Typography className="text-right" variant="body1" component="p">
                123.45
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs className="overflow-hidden">
              <Typography className="op-0-6" variant="body1" component="p">
                Current Bill:
              </Typography>
            </Grid>
            <Grid item xs className="flex-0-0-a">
              <Typography className="text-right" variant="body1" component="p">
                123.45
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
