import React from 'react'
import Grid from "@material-ui/core/Grid";
import Skeleton from '@material-ui/lab/Skeleton';

export default function Loading() {
  return (
    <div>
      <Grid container className="pt-3 pb-3 pl-1 pr-1">
        <Grid item xs={8} className="pr-3 pt-2 pb-2 border-right">
          <Skeleton variant="text" animation="wave" width="30%" className="pb-3" />
          <Skeleton variant="text" animation="wave" width="20%" className="pb-2" />
          <Grid container className="pb-2">
            <Grid item xs={12} className="align-items-center">
              <Skeleton variant="text" animation="wave" width="100%" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={12} className="align-items-center">
              <Skeleton variant="text" animation="wave" width="100%" />
            </Grid>
          </Grid>
          <Grid container className="pb-2 pt-3">
            <Grid item xs={12}>
              <Skeleton variant="text" animation="wave" width="50%" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={12} className="align-items-center">
              <Skeleton variant="text" animation="wave" width="100%" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={12} className="align-items-center">
              <Skeleton variant="text" animation="wave" width="100%" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={12} className="align-items-center">
              <Skeleton variant="text" animation="wave" width="100%" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className="pl-3 pt-2 pb-2">
          <Skeleton variant="text" animation="wave" width="60%" className="pb-3" />
          <Grid container className="pb-2">
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
          <Grid container className="pb-2 pt-3">
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={8}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
          <Grid container className="pb-2 pt-2">
            <Grid item xs={8} className="align-bottom">
              <Skeleton variant="text" animation="wave" width="80%" />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" animation="wave" width="80%" className="ml-auto" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}