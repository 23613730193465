import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./style.scss";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Card, CardContent, IconButton } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import cliTruncate from 'cli-truncate';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles(theme => ({
    showpop: {
        position: "relative",
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    active: {
        "&:before": {
            content: "''",
            backgroundColor: theme.palette.primary.main,
            height: "100%",
            width: 5,
            position: "absolute",
            borderBottomLeftRadius: 4,
            borderTopLeftRadius: 4,
        }
    },
    inactive: {
        "&:before": {
            content: "''",
            backgroundColor: "#373d49",
            height: "100%",
            width: 5,
            position: "absolute",
            borderBottomLeftRadius: 4,
            borderTopLeftRadius: 4,
        }
    },
    menu: {
        zIndex: '1500!important'
    }
}));

export default function Attachment(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [active, setActive] = React.useState(false);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setActive(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActive(false);
    };

    return (
        <div className="attachment">
            <Card className={[classes.showpop, active ? classes.active : classes.inactive].join(' ')}>
                <CardContent className="py-2 pl-3 pr-2">
                    <Grid container item xs={12} alignItems="center" justify="space-between">
                        <div className="p-l-10">
                            <Typography>
                                {cliTruncate('SystemPlatformAttachment.pdf', 20, { position: 'middle', preferTruncationOnSpace: true })}
                            </Typography>
                            <Typography variant="body2" className="text-secondary">29 May, 2019 | 15:06:18</Typography>
                        </div>
                        <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                </CardContent>
            </Card>
            <Menu
                id="attach-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
            >
                <MenuItem onClick={handleClose}><VisibilityOutlinedIcon className="mr-2" />View</MenuItem>
                <MenuItem onClick={handleClose}><GetAppOutlinedIcon className="mr-2" />Download</MenuItem>
                <MenuItem onClick={handleClose}><DeleteOutlinedIcon className="mr-2" />Delete</MenuItem>
            </Menu>
        </div>
    );
}