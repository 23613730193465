import React, { Suspense } from "react";
import "./style.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CallIcon from "@material-ui/icons/Call";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Headbar from "../headbar";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CheckIcon from "@material-ui/icons/Check";
import Loading from '../loading/contact'
import IconButton from '@material-ui/core/IconButton'

const LazyContact = React.lazy(() =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(
      import("./index").then(module => ({ default: module.Contact }))
    ), 5000)
  )
);

export function Contact() {
  const [cannotEdit, setCannotEdit] = React.useState(true)
  const [cannotEdit2, setCannotEdit2] = React.useState(true)

  const clickEdit = () => {
    setCannotEdit(!cannotEdit)
  };
  const clickEdit2 = () => {
    setCannotEdit2(!cannotEdit2)
  };

  return (
    <div>
      <Typography>
        Main Contact
      </Typography>
      <Grid container alignItems="center" justify="center" className="mt-2">
        <Grid container item xs={10} alignItems="center" justify="center">
          <Grid item xs={3}>
            <img className="img-fluid" src="/assets/images/icon/individual@3x.png" alt="" />
          </Grid>
          <Grid item xs={9} className="contact-wrap">
            <TextField
              InputProps={{
                readOnly: cannotEdit
              }}
              defaultValue="จิตดี คุณธรรม"
            />
            <TextField
              InputProps={{
                readOnly: cannotEdit
              }}
              defaultValue="096-976-3222"
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={clickEdit}>
            <BorderColorIcon className={cannotEdit ? "" : "d-none"} />
            <CheckIcon className={cannotEdit ? "d-none" : ""} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider className="my-3" />
      <Typography>
        Last Contact
      </Typography>
      <Grid container alignItems="center" justify="center" className="mt-2">
        <Grid container item xs={10} alignItems="center" justify="center">
          <Grid item xs={3}>
            <img className="img-fluid" src="/assets/images/icon/individual@3x.png" alt="" />
          </Grid>
          <Grid item xs={9} className="contact-wrap">
            <TextField
              InputProps={{
                readOnly: cannotEdit2
              }}
              defaultValue="จิตดี คุณธรรม"
            />
            <TextField
              InputProps={{
                readOnly: cannotEdit2
              }}
              defaultValue="096-976-3222"
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={clickEdit2}>
            <BorderColorIcon className={cannotEdit2 ? "" : "d-none"} />
            <CheckIcon className={cannotEdit2 ? "d-none" : ""} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default function Index() {
  return (
    <Card square>
      <Headbar icon={<CallIcon />} title="Contact" />
      <CardContent>
        <Suspense fallback={<Loading />}>
          <LazyContact />
        </Suspense>
      </CardContent>
    </Card>
  )
}
