import React from "react";
import "./style.scss";
import "swiper/dist/css/swiper.min.css";
import Swiper from "react-id-swiper";
import Typography from "@material-ui/core/Typography";

export default function AppInfo() {
    const params = {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        rebuildOnUpdate: true,
    }

    return (
        <div className="app-info pb-4">
            <Typography variant="h6" className="pt-4 pb-2">
                App Info (4/5)
            </Typography>
            <Swiper {...params}>
                <div className="items p-3 text-center">
                    <div className="app-icon p-b-10">
                        <img
                            className="img-fluid app-service"
                            src="/assets/images/icon/app-trueiservice@3x.png"
                            width="60px"
                            alt=""
                        />
                        <img
                            className="img-fluid app-os"
                            src="/assets/images/icon/method_ios@3x.png"
                            width="20px"
                            alt=""
                        />
                    </div>
                    <div className="app-desc">
                        <Typography variant="caption" component="div">iService</Typography>
                        <Typography variant="caption" component="div" className="op-0-6">22 Apr, 2019 | 18:30</Typography>
                    </div>
                </div>
                <div className="items p-3 text-center">
                    <div className="app-icon p-b-10">
                        <img
                            className="img-fluid app-service"
                            src="/assets/images/icon/app-trueid@3x.png"
                            width="60px"
                            alt=""
                        />
                        <img
                            className="img-fluid app-os"
                            src="/assets/images/icon/method_android@3x.png"
                            width="20px"
                            alt=""
                        />
                    </div>
                    <div className="app-desc">
                        <Typography variant="caption" className="d-block">trueID</Typography>
                        <Typography variant="caption" className="op-0-6">22 Apr, 2019 | 18:30</Typography>
                    </div>
                </div>
                <div className="items p-3 text-center">
                    <div className="app-icon p-b-10">
                        <img
                            className="img-fluid app-service"
                            src="/assets/images/icon/app-trueyou@3x.png"
                            width="60px"
                            alt=""
                        />
                    </div>
                    <div className="app-desc">
                        <Typography variant="caption" className="d-block">trueYOU</Typography>
                        <Typography variant="caption" className="op-0-6"> - </Typography>
                    </div>
                </div>
                <div className="items p-3 text-center">
                    <div className="app-icon p-b-10">
                        <img
                            className="img-fluid app-service"
                            src="/assets/images/icon/app-truetv@3x.png"
                            width="60px"
                            alt=""
                        />
                        <img
                            className="img-fluid app-os"
                            src="/assets/images/icon/method_web@3x.png"
                            width="20px"
                            alt=""
                        />
                    </div>
                    <div className="app-desc">
                        <Typography variant="caption" className="d-block">trueTV</Typography>
                        <Typography variant="caption" className="op-0-6">22 Apr, 2019 | 18:30</Typography>
                    </div>
                </div>
            </Swiper>
        </div>
    );
}