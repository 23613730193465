import React from "react";
import { Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import ServiceRequest from '../details/service-request'

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: 56,
    },
    grid: {
        padding: theme.spacing(2)
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
    },
    info: {
        backgroundColor: theme.palette.info.main,
    },
    success: {
        backgroundColor: theme.palette.success.main,
    },
    number: {
        padding: '3px 25px',
        borderRadius: 6,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    alltask: {
        backgroundImage: 'linear-gradient(to right, #6f69c6, #c15579)',
    },
    minbox: {
        minHeight: '155px',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
    },
    followup: {
        backgroundColor: '#6DC4DA',
        borderRadius: '50px',
        padding: '5px',
        maxWidth: '100%',
        height: 'auto',
    },
    pendingclose: {
        backgroundColor: '#7269CE',
        borderRadius: '50px',
        padding: '5px',
        maxWidth: '100%',
        height: 'auto',
    },
    todo: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50px',
        padding: '5px',
        maxWidth: '100%',
        height: 'auto',
    },
    gridborder: {
        position: 'relative',
        '&:before': {
            content: '""',
            border: '1px dashed rgba(0,0,0,.24)',
            width: '1px',
            height: 'calc(100% - 40px)',
            position: 'absolute',
            top: '0',
            left: '0',
            margin: '20px auto',
        },
        '&:after': {
            content: '""',
            border: '1px dashed rgba(0,0,0,.24)',
            width: '1px',
            height: 'calc(100% - 40px)',
            position: 'absolute',
            top: '0',
            right: '0',
            margin: '20px auto',
        },
    }
}));



export default function MyTask() {
    const classes = useStyles();

    return (
        <div>
            <Grid container className="py-3">
                <Grid item xs={12} sm={6}>
                    <Card className={[classes.alltask, classes.minbox].join(' ')}>
                        <CardActionArea className="h-100">
                            <Link to="#">
                                <CardContent className="p-3">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <Typography variant="h5" className="text-white">
                                                All Task
                                            </Typography>
                                            <Typography variant="body1" className="text-white">
                                                January 2020
                                            </Typography>
                                        </div>
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/icon/crm_task_white@3x.png"
                                            width="40px"
                                            alt=""
                                        />
                                    </div>
                                    <Typography variant="h4" gutterBottom className="pt-3 text-white">
                                        <span>
                                            12
                                        </span>
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card className={classes.minbox}>
                        <Grid container>
                            <Grid item xs={4}>
                                <CardActionArea className="h-100">
                                    <Link to="#">
                                        <CardContent className="p-3">
                                            <div align="center">
                                                <img
                                                    className={classes.followup}
                                                    src="/assets/images/icon/crm_task_white@3x.png"
                                                    width="40px"
                                                    alt=""
                                                />
                                            </div>
                                            <Typography variant="h6" align="center" className="pt-2">
                                                Follow Up
                                            </Typography>
                                            <Typography variant="h4" align="center" className="pt-3">
                                                <span>
                                                    12
                                                </span>
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Grid>
                            <Grid item xs={4} className={classes.gridborder}>
                                <CardActionArea className="h-100">
                                    <Link to="#">
                                        <CardContent className="p-3">
                                            <div align="center">
                                                <img
                                                    className={classes.pendingclose}
                                                    src="/assets/images/icon/crm_task_white@3x.png"
                                                    width="40px"
                                                    alt=""
                                                />
                                            </div>
                                            <Typography variant="h6" align="center" className="pt-2">
                                                Pending Close
                                            </Typography>
                                            <Typography variant="h4" align="center" className="pt-3">
                                                <span>
                                                    60
                                                </span>
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Grid>
                            <Grid item xs={4}>
                                <CardActionArea className="h-100">
                                    <Link to="#">
                                        <CardContent className="p-3">
                                            <div align="center">
                                                <img
                                                    className={classes.todo}
                                                    src="/assets/images/icon/crm_task_white@3x.png"
                                                    width="40px"
                                                    alt=""
                                                />
                                            </div>
                                            <Typography variant="h6" align="center" className="pt-2">
                                                To Do
                                            </Typography>
                                            <Typography variant="h4" align="center" className="pt-3">
                                                <span>
                                                    12
                                                </span>
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {/* <Grid item xs={12} sm={6} lg={3} className={classes.grid}>
                    <Card>
                        <CardActionArea>
                            <Link to="#">
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        Padding Task
                                    </Typography>
                                    <Typography variant="h6" align="center" gutterBottom className="pt-3">
                                        <span className={classes.number}>
                                            12
                                        </span>
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} className={classes.grid}>
                    <Card>
                        <CardActionArea>
                            <Link to="#">
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        Follow Task
                                    </Typography>
                                    <Typography variant="h6" align="center" gutterBottom className="pt-3">
                                        <span className={classes.number}>
                                            7
                                        </span>
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} className={classes.grid}>
                    <Card>
                        <CardActionArea>
                            <Link to="#">
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        Wating Close
                                    </Typography>
                                    <Typography variant="h6" align="center" gutterBottom className="pt-3">
                                        <span className={classes.number}>
                                            3
                                        </span>
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} className={classes.grid}>
                    <Card>
                        <CardActionArea>
                            <Link to="#">
                                <CardContent>
                                    <Typography variant="h6" align="center">
                                        Activity Assign
                                    </Typography>
                                    <Typography variant="h6" align="center" gutterBottom className="pt-3">
                                        <span className={classes.number}>
                                            2
                                        </span>
                                    </Typography>
                                </CardContent>
                            </Link>
                        </CardActionArea>
                    </Card>
                </Grid> */}
            </Grid>

            <ServiceRequest />
        </div>
    );
}