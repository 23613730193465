import React from "react";
import "./style.scss";
import "swiper/dist/css/swiper.min.css";
import Swiper from "react-id-swiper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";

export default function Contact() {
    const params = {
        slidesPerView: 'auto',
        spaceBetween: 5,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        rebuildOnUpdate: true,
    }

    return (
        <div className="asset-contact pt-4 pb-4">
            <Typography variant="h6" className="pb-2">Contract</Typography>
            <Swiper {...params}>
                {[...Array(5)].map((items, index) =>
                    <div key={index} className="items p-3">
                        <Typography variant="body2" className="font-weight-bold text-nowrap">
                            RMV000000004235573
                    </Typography>
                        <Typography variant="body2" className="text-nowrap">
                            Retention_High Value RC500 Up_ContentRetention
                    </Typography>
                        <div className="d-flex align-items-center p-t-6 p-b-4">
                            <Typography variant="body2">Term: 12</Typography>
                            <Divider
                                className="border-vertical mx-1"
                                orientation="vertical"
                            />
                            <Typography variant="body2">Fee: 6,000</Typography>
                            <Divider
                                className="border-vertical mx-1"
                                orientation="vertical"
                            />
                            <Typography variant="body2">Terminate: 10 Sep, 2020</Typography>
                        </div>
                        <Chip label={
                            <Typography variant="body2" className="textnormal">Expire Date: 28 Aug, 2099 | 28,995 Days</Typography>
                        } className="bg-danger text-white p-1" />
                    </div>
                )}
            </Swiper>
        </div>
    );
}