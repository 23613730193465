import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./style.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Expansion from "./expansion.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Offer from "./../offer";
import Loading from './../loading/asset-profile'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Profile from './profile'
import Contact from "./contact";
import Information from './information'
import AppInfo from './app-info'
import Headbar from './../headbar'

const LazyAssetProfile = React.lazy(() =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(
      import("./index").then(module => ({ default: module.AssetProfile }))
    ), 3000)
  )
);

const useStyles = makeStyles(theme => ({
  dialogContent: {
    margin: "32px 32px 32px 79px"
  },
}));


export function AssetProfile(props) {
  return (
    <div>
      <Profile {...props} />
      <Divider />
      <Contact />
      <Divider />
      <AppInfo />
      <Divider />
      <Information />
    </div >
  );
}

export default function Index(props) {
  const classes = useStyles();
  const [openOffer, setOpenOffer] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleCloseOffer = () => {
    setOpenOffer(false);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Card square className="root">
        <div className="asset-profile-list">
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                placement="right-start"
                title={<Expansion {...props} />}
              >
                <div>
                  <Headbar
                    icon={<WorkOutlineOutlinedIcon />}
                    iconClick={<AutorenewIcon />}
                    title="Asset Profile"
                    button
                    onClick={handleTooltipOpen} />
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>

        <CardContent>
          <Suspense fallback={<Loading />}>
            <LazyAssetProfile {...props} />
          </Suspense>
        </CardContent>
      </Card>
      <Dialog
        open={openOffer}
        onClose={handleCloseOffer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={classes.dialogContent}
      >
        <DialogContent>
          <Offer />
        </DialogContent>
      </Dialog>
    </div>
  )
}