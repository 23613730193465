import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  titlebill: {
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px"
  }
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div className="p-4">
      <div className={classes.titlebill}>&nbsp;</div>
      <Paper elevation={0} className="p-4">
        <form noValidate autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <Skeleton
                variant="text"
                animation="wave"
                width="160px"
                height="30px"
              />
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="70px" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="70px" />
                  <Skeleton variant="text" animation="wave" width="120px" />
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="70px" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="70px" />
                  <Skeleton variant="text" animation="wave" width="120px" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant="text"
                animation="wave"
                width="160px"
                height="30px"
              />
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="50px" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="120px" />
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="70px" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="120px" />
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="100px" />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" animation="wave" width="120px" />
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className="pt-4">
              <Grid container item xs={1}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="80px"
                  height="50px"
                />
              </Grid>
              <Grid
                direction="row"
                container
                item
                xs={11}
                className="justify-content-end d-flex"
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="80px"
                  height="50px"
                  className="ml-1"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="50px"
                  height="50px"
                  className="ml-1"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="60px"
                  height="50px"
                  className="ml-3"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="70px"
                  height="50px"
                  className="ml-1"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="70px"
                  height="50px"
                  className="ml-1"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="70px"
                  height="50px"
                  className="ml-1"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Skeleton
        variant="text"
        animation="wave"
        width="70px"
        height="40px"
        className="mx-auto mt-4 mb-4"
      />
      <Grid container>
        <Grid item xs={4}>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="66px"
          />
        </Grid>
        <Grid item xs={4} className="px-4">
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="66px"
          />
        </Grid>
        <Grid item xs={4}>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="66px"
          />
        </Grid>
      </Grid>
    </div>
  );
}
