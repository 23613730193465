import React from "react";
import './style.scss'
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, IconButton } from "@material-ui/core";
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Detail from './detail.js'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        margin: theme.spacing(0, 2, 2, 0)
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    }
}));

export default function Note() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <div className="note">
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        interactive
                        placement="left-end"
                        title={<Detail />}
                    >
                        <IconButton className={classes.root} onClick={handleTooltipOpen}>
                            <Avatar className={classes.avatar}>
                                <CommentOutlinedIcon />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </div>
            </ClickAwayListener>
        </div>
    );
}