import React from 'react'
import Field from './field.js'
import Table from './table.js'
import TocIcon from '@material-ui/icons/Toc';

export default function AssetDetail() {
    return (
        <div>
            <Field icon={<TocIcon />} headTitle={'Main Profile'} />
            <Table icon={<TocIcon />} headTitle={'Priceplan'} />
            <Table icon={<TocIcon />} headTitle={'Contract'} />
            <Table icon={<TocIcon />} headTitle={'Discount'} />
            <Table icon={<TocIcon />} headTitle={'Additional Offer'} />
            <Table icon={<TocIcon />} headTitle={'Equipment'} />
            <Table icon={<TocIcon />} headTitle={'Other'} />
        </div>
    )
}
