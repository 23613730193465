import React, { useEffect } from "react";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import BillingBox from "./billing-box";

const useStyles = makeStyles(theme => ({
  leftHorizon: {
    width: "2px",
    position: "absolute",
    left: "calc(33.3333% + 2rem)",
    top: "136px",
    background: "#333",
    transition: "all 0.3s ease-out"
  },
  rightHorizon: {
    width: "2px",
    position: "absolute",
    left: "calc(66.6666% + 1rem)",
    top: "136px",
    background: "#aa0000",
    transition: "all 0.3s ease-out"
  }
}));

export default function BillingTabsAll() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [lineColor, setLineColor] = React.useState("0");
  const [leftLinePosition, setLeftLinePosition] = React.useState("0");
  const [leftLineHeight, setLeftLineHeight] = React.useState("0");
  const [rightLinePosition, setRightLinePosition] = React.useState("0");
  const [rightLineHeight, setRightLineHeight] = React.useState("0");

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (expanded === "panel1") {
      setLineColor("#d3536c");
      setLeftLinePosition("136px");
      setLeftLineHeight("606px");
      setRightLinePosition("136px");
      setRightLineHeight("390px");
    } else if (expanded === "panel3") {
      setLineColor("#73c0a1");
      setLeftLinePosition("116px");
      setLeftLineHeight("186px");
      setRightLinePosition("136px");
      setRightLineHeight("0px");
    } else if (expanded === "panel4") {
      setLineColor("#6e69c1");
      setLeftLinePosition("136px");
      setLeftLineHeight("0px");
      setRightLinePosition("96px");
      setRightLineHeight("288px");
    } else if (expanded === "panel6") {
      setLineColor("#73c0a1");
      setLeftLinePosition("116px");
      setLeftLineHeight("504px");
      setRightLinePosition("96px");
      setRightLineHeight("0px");
    } else if (expanded === "panel7") {
      setLineColor("#d3536c");
      setLeftLinePosition("578px");
      setLeftLineHeight("42px");
      setRightLinePosition("96px");
      setRightLineHeight("0px");
    } else {
      setLineColor("transparent");
      setLeftLineHeight("0");
      setRightLineHeight("0");
    }
  }, [expanded]);

  return (
    <div className="diagram-wrap">
      <div className="year-wrap">
        <div className="theYear">
          <Typography variant="body1">2020</Typography>
        </div>
        <Typography variant="body2">(5 Records)</Typography>
        <Grid container className="day-wrap">
          <div className="theDate current">
            <div className="have-invoice">
              <Typography>
                FRI<span>24</span>
              </Typography>
            </div>
          </div>
          <Grid container item xs={4} className="payment-wrap pl-4 pr-4"></Grid>
          <Grid container item xs={4} className="invoice-wrap pl-4 pr-4">
            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className={
                expanded === "panel1"
                  ? "toPayment toAdjustment"
                  : expanded === "panel3"
                  ? "fromPayment"
                  : expanded === "panel6"
                  ? "fromPayment"
                  : expanded === "panel4"
                  ? "fromAdjustment"
                  : ""
              }
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  Invoice Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  date="24 Jan, 2020"
                  duedate="24 Jan, 2020"
                  amount="599.00"
                  payment="500.00"
                  adjust="99.00"
                  status="Closed"
                  document="/assets/mock/mock_invoice_document.png"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid
            container
            item
            xs={4}
            className="adjustment-wrap pl-4 pr-4"
          ></Grid>
        </Grid>
        <Grid container className="day-wrap">
          <div className="theDate">
            <div className="have-payment">
              <Typography>
                SAT<span>18</span>
              </Typography>
            </div>
          </div>
          <Grid container item xs={4} className="payment-wrap pl-4 pr-4">
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  PayMent Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  date="24 Jan, 2020"
                  postdate="24 Jan, 2020"
                  channel="Customer Service"
                  amount="500.00"
                  line1="Silom"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className={
                expanded === "panel1"
                  ? "fromInvoice"
                  : expanded === "panel3"
                  ? "toInvoice"
                  : ""
              }
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  PayMent Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  date="24 Jan, 2020"
                  postdate="24 Jan, 2020"
                  channel="Customer Service"
                  amount="500.00"
                  line1="Ekamai"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid container item xs={4} className="invoice-wrap pl-4 pr-4"></Grid>
          <Grid
            container
            item
            xs={4}
            className="adjustment-wrap pl-4 pr-4"
          ></Grid>
        </Grid>
        <Grid container className="day-wrap">
          <div className="theDate">
            <div className="">
              <Typography>
                WED<span>15</span>
              </Typography>
            </div>
          </div>
          <Grid container item xs={4} className="payment-wrap pl-4 pr-4"></Grid>
          <Grid container item xs={4} className="invoice-wrap pl-4 pr-4"></Grid>
          <Grid container item xs={4} className="adjustment-wrap pl-4 pr-4">
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              className={
                expanded === "panel1"
                  ? "fromInvoice"
                  : expanded === "panel4"
                  ? "toInvoice"
                  : ""
              }
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  Adjustment Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  level="invoice"
                  description="AD3"
                  reason="002"
                  amount="99.00"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  Adjustment Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  level="invoice"
                  description="AD3"
                  reason="002"
                  amount="99.00"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </div>
      <div className="year-wrap">
        <div className="theYear">
          <Typography variant="body1">2019</Typography>
        </div>
        <Typography variant="body2">(3 Records)</Typography>
        <Grid container className="day-wrap">
          <div className="theDate">
            <div className="have-payment">
              <Typography>
                FRI<span>20</span>
              </Typography>
            </div>
          </div>
          <Grid container item xs={4} className="payment-wrap pl-4 pr-4">
            <ExpansionPanel
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              className={
                expanded === "panel1"
                  ? "fromInvoice"
                  : expanded === "panel6"
                  ? "toInvoice"
                  : expanded === "panel7"
                  ? "fromInvoice"
                  : ""
              }
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  PayMent Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  date="24 Jan, 2020"
                  postdate="24 Jan, 2020"
                  channel="Customer Service"
                  amount="500.00"
                  line1="Silom"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid container item xs={4} className="invoice-wrap pl-4 pr-4">
            <ExpansionPanel
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
              className={
                expanded === "panel7"
                  ? "toPayment"
                  : expanded === "panel6"
                  ? "fromPayment"
                  : ""
              }
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  Invoice Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  date="24 Jan, 2020"
                  duedate="24 Jan, 2020"
                  amount="599.00"
                  payment="500.00"
                  adjust="99.00"
                  status="Closed"
                  document="/assets/mock/mock_invoice_document.png"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid container item xs={4} className="adjustment-wrap pl-4 pr-4">
            <ExpansionPanel
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  Adjustment Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  level="invoice"
                  description="AD3"
                  reason="002"
                  amount="99.00"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </div>
      <div className="year-wrap">
        <div className="theYear">
          <Typography variant="body1">2018</Typography>
        </div>
        <Typography variant="body2">(1 Records)</Typography>
        <Grid container className="day-wrap">
          <div className="theDate">
            <div className="have-invoice">
              <Typography>
                MON<span>25</span>
              </Typography>
            </div>
          </div>
          <Grid container item xs={4} className="payment-wrap pl-4 pr-4"></Grid>
          <Grid container item xs={4} className="invoice-wrap pl-4 pr-4">
            <ExpansionPanel
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <Typography className="box-header">
                  1,099<span>.00</span>
                </Typography>
                <Typography className="box-subheader">
                  Invoice Amount
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BillingBox
                  date="24 Jan, 2020"
                  duedate="24 Jan, 2020"
                  amount="599.00"
                  payment="500.00"
                  adjust="99.00"
                  status="Closed"
                  document="/assets/mock/mock_invoice_document.png"
                ></BillingBox>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid
            container
            item
            xs={4}
            className="adjustment-wrap pl-4 pr-4"
          ></Grid>
        </Grid>
      </div>

      <div
        className={classes.leftHorizon}
        style={{
          top: leftLinePosition,
          height: leftLineHeight,
          background: lineColor
        }}
      ></div>
      <div
        className={classes.rightHorizon}
        style={{
          top: rightLinePosition,
          height: rightLineHeight,
          background: lineColor
        }}
      ></div>
    </div>
  );
}
