import React from "react";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Progress } from "antd";
import Active from "./active.js";
import InActive from "./inactive.js";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function Usage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="d-flex justify-content-end pt-2">
        <AppBar className="tabs-toggle" position="static">
          <Tabs value={value} onChange={handleChange} indicatorColor="primary">
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Inactive" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </div>
      <Grid container className="pt-3 pb-3 pl-1 pr-1">
        <Grid item xs={8} className="pr-3 pt-2 pb-2 border-right">
          <Typography
            className="font-weight-bold pb-3"
            variant="body1"
            component="p"
          >
            Current Usage
          </Typography>
          <Typography
            className="font-weight-bold pb-2"
            variant="body2"
            component="p"
          >
            Voice
          </Typography>
          <Grid container className="pb-2">
            <Grid item xs={2} className="d-flex align-items-center">
              <Typography variant="body2" component="p">
                In
              </Typography>
            </Grid>
            <Grid item xs={7} className="d-flex align-items-center">
              <Progress
                percent={100}
                showInfo={false}
                strokeWidth={13}
                strokeColor="#F4516C"
              />
            </Grid>
            <Grid
              item
              xs
              className="pl-2 white-nowrap d-flex align-items-center"
            >
              <Typography variant="body2" component="p" className="text-right">
                120/120 min.
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={2} className="d-flex align-items-center">
              <Typography variant="body2" component="p">
                Out
              </Typography>
            </Grid>
            <Grid item xs={7} className="d-flex align-items-center">
              <Progress
                percent={10}
                status="active"
                showInfo={false}
                strokeWidth={13}
                strokeColor="#F4516C"
              />
            </Grid>
            <Grid
              item
              xs
              className="pl-2 white-nowrap d-flex align-items-center"
            >
              <Typography variant="body2" component="p" className="text-right">
                10 min.
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="pb-2 pt-3">
            <Grid item xs={2}>
              <Typography
                className="font-weight-bold"
                variant="body2"
                component="p"
              >
                Data
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2" component="p">
                Fair Speed: 123 kpbs
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={2} className="d-flex align-items-center">
              <Typography variant="body2" component="p">
                In
              </Typography>
            </Grid>
            <Grid item xs={7} className="d-flex align-items-center">
              <Progress
                percent={80}
                showInfo={false}
                strokeWidth={13}
                strokeColor="#F4516C"
              />
            </Grid>
            <Grid
              item
              xs
              className="pl-2 white-nowrap d-flex align-items-center"
            >
              <Typography variant="body2" component="p" className="text-right">
                20.1/25 GB.
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={2} className="d-flex align-items-center">
              <Typography variant="body2" component="p">
                SMS
              </Typography>
            </Grid>
            <Grid item xs={7} className="d-flex align-items-center">
              <Progress
                percent={0}
                status="active"
                showInfo={false}
                strokeWidth={13}
                strokeColor="#F4516C"
              />
            </Grid>
            <Grid
              item
              xs
              className="pl-2 white-nowrap d-flex align-items-center"
            >
              <Typography variant="body2" component="p" className="text-right">
                0/10 MB.
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid item xs={2} className="d-flex align-items-center">
              <Typography variant="body2" component="p">
                Wifi
              </Typography>
            </Grid>
            <Grid item xs={7} className="d-flex align-items-center">
              <Progress
                percent={100}
                showInfo={false}
                strokeWidth={13}
                strokeColor="#F4516C"
              />
            </Grid>
            <Grid
              item
              xs
              className="pl-2 white-nowrap d-flex align-items-center"
            >
              <Typography variant="body2" component="p" className="text-right">
                Unlimited
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className="pl-3 pt-2 pb-2">
          <TabPanel className="p-0" value={value} index={0}>
            <Active />
          </TabPanel>
          <TabPanel className="p-0" value={value} index={1}>
            <InActive />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}
