import React, { Suspense } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Headbar from "../headbar";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SRShorten from "./sr-shorten";
import Suggestion from "./suggestion";
import AFSOder from "./afs-order";
import AccountMaintenace from "./account-maintenace";
import ReprintBill from "./reprint-bill";
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import LoadingSrShorten from '../loading/sr-shorten'
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import muiTooltip from "@material-ui/core/Tooltip";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import _ from 'lodash'
import ComputerOutlinedIcon from '@material-ui/icons/ComputerOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

const LazySRShorten = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MySRShorten }))
        ), 500)
    )
);
export function MySRShorten() {
    return <SRShorten />
}

const LazySuggestion = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MySuggestion }))
        ), 500)
    )
);
export function MySuggestion() {
    return <Suggestion />
}

const LazyAFSOder = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyAFSOder }))
        ), 500)
    )
);
export function MyAFSOder() {
    return <AFSOder />
}

const LazyAccountMaintenace = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyAccountMaintenace }))
        ), 500)
    )
);
export function MyAccountMaintenace() {
    return <AccountMaintenace />
}

const LazyReprintBill = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.MyReprintBill }))
        ), 500)
    )
);
export function MyReprintBill() {
    return <ReprintBill />
}

const useStyles = makeStyles(theme => ({
    afssLeft: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: 52,
        height: 300,
        overflowY: 'auto',
        backgroundColor: '#f4f5f9',
        zIndex: 1,
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 10px',
        borderWidth: '0 1px 1px 1px',
        borderColor: '#ececf1',
        borderStyle: 'solid',
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            borderWidth: '1px 0 0 0',
            borderColor: '#ececf1',
            borderStyle: 'solid',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    active: {
        borderWidth: '1px 0 0 0',
        borderColor: '#ececf1',
        borderStyle: 'solid',
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    afssLeftOpen: {
        width: 240,
        '& p': {
            display: 'block'
        }
    },
    btnMenu: {
        position: 'absolute',
        marginTop: 13,
        marginLeft: 225,
        zIndex: 1
    },
    btnMenuClose: {
        marginLeft: 39,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    afssRight: {
        width: 'calc(100% - 52px)',
        marginLeft: 52,
        height: 300,
        overflowY: 'auto',
        paddingLeft: theme.spacing(4)
    },
    listitem: {
        padding: theme.spacing(1, 2),
        "&:hover": {
            backgroundColor: 'unset',
            color: theme.palette.primary.main
        }
    },
}));

const Tooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary
    },
    arrow: {
        left: '10px!important',
        boxSizing: 'border-box',
        '&::before': {
            border: '1em solid black',
            boxSizing: 'border-box',
            content: '',
            position: 'absolute',
            width: '10px',
            height: '6px',
            borderColor: 'transparent transparent #bada55 #bada55',
            transformOrigin: '0 0',
            transform: 'rotate(45deg)',
            boxShadow: '-2px 2px 4px -3px rgba(0, 0, 0, 0.4)',
            left: '-2px',
            backgroundColor: theme.palette.common.white,
        },
        color: theme.palette.common.white,
    }
}))(muiTooltip);

function ListMenu(props) {
    const classes = useStyles();
    const [menu] = React.useState([
        {
            name: 'SR Shorten',
            icon: 'ComputerOutlinedIcon',
            submenu: false
        },
        {
            name: 'Suggestion',
            icon: 'AssignmentTurnedInOutlinedIcon',
            submenu: [
                { listsub: 'Create New Post' },
                { listsub: 'Manage Orders' },
                { listsub: 'Customer Feedbacks' },
                { listsub: 'Register Member' }
            ]
        },
        {
            name: 'AFS Order',
            icon: 'ListAltOutlinedIcon',
            submenu: false
        },
        {
            name: 'Account Maintenace',
            icon: 'BuildOutlinedIcon',
            submenu: false
        },
        {
            name: 'Reprint Bill',
            icon: 'ReceiptOutlinedIcon',
            submenu: [
                { listsub: 'Create New Post' },
                { listsub: 'Manage Orders' },
                { listsub: 'Customer Feedbacks' },
                { listsub: 'Register Member' }
            ]
        }
    ])

    const componentsIcon = {
        ComputerOutlinedIcon: ComputerOutlinedIcon,
        AssignmentTurnedInOutlinedIcon: AssignmentTurnedInOutlinedIcon,
        ListAltOutlinedIcon: ListAltOutlinedIcon,
        BuildOutlinedIcon: BuildOutlinedIcon,
        ReceiptOutlinedIcon: ReceiptOutlinedIcon
    };

    const { open, submenuOpen, tabs, handleTabs, handleSubmenuOpen, handleSubmenuClose } = props

    return (
        <div className={[classes.afssLeft, open && classes.afssLeftOpen, 'trans-0-1'].join(' ')}>
            {menu.map((items, index) => {
                const DynamicComponentIcon = componentsIcon[items.icon];
                return (
                    <div key={index}>
                        {!_.isEmpty(items.submenu) ?
                            <Tooltip
                                arrow
                                placement="right-start"
                                title={
                                    <List component="nav" aria-label="secondary mailbox folders">
                                        {!_.isEmpty(items.submenu) && items.submenu.map((items, index2) =>
                                            <ListItem key={index2} button className={classes.listitem} onClick={handleTabs(index + 1)}>
                                                <ListItemText primary={items.listsub} />
                                            </ListItem>
                                        )}
                                    </List>
                                }
                                className={classes.tooltip}
                                interactive
                                open={submenuOpen === index ? true : false}
                                onClose={handleSubmenuClose}
                                onOpen={() => handleSubmenuOpen(index)}
                            >
                                <div className={[classes.menu, tabs === (index + 1) && classes.active].join(' ')} onClick={handleTabs(index + 1)}>
                                    <div className="d-flex">
                                        <DynamicComponentIcon className="ml-2 mr-1" />
                                        <Typography noWrap className="pl-3">{items.name}</Typography>
                                    </div>
                                    <ChevronRightOutlinedIcon />
                                </div>
                            </Tooltip>
                            :
                            <div className={[classes.menu, tabs === (index + 1) && classes.active].join(' ')} onClick={handleTabs(index + 1)}>
                                <div className="d-flex">
                                    <DynamicComponentIcon className="ml-2 mr-1" />
                                    <Typography noWrap className="pl-3">{items.name}</Typography>
                                </div>
                            </div>
                        }
                    </div >
                )
            }
            )}
        </div >
    )
}

export default function Index() {
    const classes = useStyles();
    const [tabs, setTabs] = React.useState(1)
    const [open, setOpen] = React.useState(false)
    const [submenuOpen, setSubmenuOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleTabs = select => event => {
        setTabs(select)
        setOpen(false)
        setSubmenuOpen(false)
    }

    const handleSubmenuOpen = select => {
        setSubmenuOpen(select)
    }

    const handleSubmenuClose = () => {
        setSubmenuOpen(false);
    };

    return (
        <Card square>
            <Headbar icon={<TableChartOutlinedIcon />} title="After Sale Service" />
            <CardContent>
                <ListMenu
                    open={open}
                    submenuOpen={submenuOpen}
                    handleSubmenuOpen={handleSubmenuOpen}
                    handleSubmenuClose={handleSubmenuClose}
                    tabs={tabs}
                    handleTabs={handleTabs} />

                <div className={[classes.btnMenu, 'trans-0-1', !open && classes.btnMenuClose].join(' ')}>
                    <Avatar className={classes.avatar} onClick={handleOpen}>
                        <IconButton>
                            <MenuOutlinedIcon fontSize="small" className="text-dark" />
                        </IconButton>
                    </Avatar>
                </div>

                <div className={classes.afssRight} onClick={handleClose}>
                    {tabs === 1 && <Suspense fallback={<LoadingSrShorten />}><LazySRShorten /></Suspense>}
                    {tabs === 2 && <Suspense fallback={<LoadingSrShorten />}><LazySuggestion /></Suspense>}
                    {tabs === 3 && <Suspense fallback={<LoadingSrShorten />}><LazyAFSOder /></Suspense>}
                    {tabs === 4 && <Suspense fallback={<LoadingSrShorten />}><LazyAccountMaintenace /></Suspense>}
                    {tabs === 5 && <Suspense fallback={<LoadingSrShorten />}><LazyReprintBill /></Suspense>}
                </div>
            </CardContent>
        </Card >
    );
}