import React from "react";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Card, CardContent, IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  showpop: {
    position: "relative",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  active: {
    "&:before": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      height: "100%",
      width: 5,
      position: "absolute",
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
    }
  },
  inactive: {
    "&:before": {
      content: "''",
      backgroundColor: "#373d49",
      height: "100%",
      width: 5,
      position: "absolute",
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
    }
  },
}));

export default function Activity(props) {
  const classes = useStyles();

  return (
    <div className="activity">
      <Card className={[classes.showpop, props.active ? classes.active : classes.inactive].join(' ')}>
        <CardContent className="py-2 pl-3 pr-2">
          <Grid container item xs={12} alignItems="center" justify="space-between">
            <div className="d-flex">
              <img
                className="img-fluid app-service"
                src="/assets/images/icon/individual@3x.png"
                width="40px"
                alt=""
              />
              <div className="p-l-10">
                <Typography>Work Assign</Typography>
                <Typography variant="body2" className="text-secondary">29 May, 2019 | 15:06:18</Typography>
              </div>
            </div>
            <IconButton onClick={props.handleTooltipOpen}>
              <img
                className="img-fluid app-service"
                src="/assets/images/icon/table_info@3x.png"
                width="20px"
                alt=""
              />
            </IconButton>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
