import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from '@material-ui/lab/Skeleton';

export default function Loading() {
  return (
    <Grid container item xs={12}>
      <Skeleton variant="rect" animation="wave" width="100%" height={300} />
    </Grid>
  )
}
