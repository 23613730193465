import { TABS_STATE } from "./actions";

const Tabs = (index = {}, action) => {
  switch (action.type) {
    case TABS_STATE:
      return action.index;
    default:
      return index;
  }
};

export default Tabs
