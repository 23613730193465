import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./style.scss";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import { Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ProfileContact from "./profile-contact";

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1)
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));


export default function Profile(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [status] = React.useState([
        {
            src: '/assets/images/icon/flag_fup@3x.png'
        },
        {
            src: '/assets/images/icon/flag_p2p@3x.png'
        },
        {
            src: '/assets/images/icon/flag_cvg@3x.png'
        }
    ]);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = value => e => {
        setOpen(value);
    };

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleShowClose = () => {
        setShow(false);
    };

    const handleShowOpen = () => {
        setShow(true);
    };

    const openAnchorEl = Boolean(anchorEl);

    return (
        <div className="profile">
            <Typography variant="h5" component="h2">
                <Card variant="outlined">
                    <CardContent className="py-3">
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item xs={12}>
                                <div className="d-flex justify-content-start align-items-center">
                                    <Typography variant="h6">
                                        {!_.isEmpty(props.uid) && props.uid}
                                    </Typography>
                                    <Divider
                                        className="border-vertical mx-2"
                                        orientation="vertical"
                                    />
                                    <Typography variant="h6">4PLITE</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" alignItems="center">
                                    <Typography variant="body2" className="text-yellow">TrueMove H</Typography>
                                    <Divider
                                        className="border-vertical mx-1"
                                        orientation="vertical"
                                    />
                                    <div className="d-flex">
                                        <div>
                                            <img
                                                className="img-fluid"
                                                src="/assets/images/icon/status_active@3x.png"
                                                width="60px"
                                                alt="" />
                                        </div>
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <div className="asset-profile-list d-flex">
                                                {status.map((items, index) =>
                                                    <div key={index}>
                                                        <Tooltip
                                                            PopperProps={{
                                                                disablePortal: true
                                                            }}
                                                            onClose={handleTooltipClose}
                                                            open={open === index ? true : false}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            arrow
                                                            title={<ProfileContact {...props} />}>
                                                            <div className="hov" onClick={handleTooltipOpen(index)}>
                                                                <img className="img-fluid" src={items.src} width="40px" alt="" />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                        </ClickAwayListener>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="d-flex justify-content-start align-items-center">
                                    <Typography variant="body2" className="align-items-end p-r-6">BAN.</Typography>
                                    <Typography variant="body1" className="p-r-6">212240605</Typography>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src="/assets/images/icon/user_vip@3x.png"
                                        className={classes.small}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={5}>
                                <Typography variant="body2" color="textSecondary">Datasubtype:</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body2">Employee</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={5}>
                                <Typography variant="body2" color="textSecondary">Aging:</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body2">1Years 3Month 14Days</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Typography>
            <div className="my-3">
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        <Typography variant="h6">
                            4G+ Super Smart 1099
                        </Typography>
                    </div>
                    <div>
                        <ClickAwayListener onClickAway={handleShowClose}>
                            <div className="asset-profile-list d-flex">
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true
                                    }}
                                    onClose={handleShowClose}
                                    open={show ? true : false}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    arrow
                                    title={
                                        <Typography
                                            color="textPrimary">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et libero tristique, dictum erat ac, finibus dui. Duis sed tempus neque, quis feugiat nunc.
                                        </Typography>
                                    }>
                                    <IconButton color="inherit" aria-label="search" onClick={handleShowOpen}>
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/icon/search@3x.png"
                                            width="20px"
                                            alt=""
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                            paper: classes.paper
                        }}
                        open={openAnchorEl}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <div>
                            <p>Since: 19 Jan,2011 07:06:07</p>
                            <p>(Activate Sub - TMV to RMV Past)</p>
                        </div>
                    </Popover>
                    <Chip
                        size="small"
                        label={
                            <Typography
                                aria-owns={openAnchorEl ? "mouse-over-popover" : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                variant="body2"
                            >
                                Discount
                            </Typography>
                        }
                        className="bg-danger text-white mx-1"
                    />
                </div>
                <div>
                    <Button variant="contained" bgcolor="text.secondary" className="w-100 my-3 py-2 no-boxshadow text-normal">
                        <img className="img-fluid p-r-10" src="/assets/images/icon/score_unhappy@3x.png" width="40px" alt="" />
                        Churn Score
                    </Button>
                </div>
            </div>
        </div>
    );
}