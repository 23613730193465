/*
 * action types
 */

export const TABS_STATE = 'TABS_STATE'

/*
 * action creators
 */


export function TABS(index) {
  return { type: TABS_STATE, index: index }
}

