import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import muiListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const ListItemText = withStyles(theme => ({
    primary: {
        color: theme.palette.text.primary
    }
}))(muiListItemText);

export default function Detail() {
    const classes = useStyles();
    const [newNote, setNewNote] = React.useState(false)
    const [data] = React.useState([
        {
            title: "17/02/2020",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            title: "17/02/2020",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            title: "17/02/2020",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            title: "17/02/2020",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        }
    ])

    const handleEdit = () => {
        setNewNote(!newNote)
    }

    return (
        <Grid container className={classes.root}>
            <Grid container item xs={12} justify="space-between" alignItems="center">
                <Typography variant="h5" component="div" color="textPrimary">
                    Note
                </Typography>
                <Button variant="outlined" color="primary" onClick={handleEdit}>
                    New
                </Button>
            </Grid>
            <Grid item xs={12}>
                {data.map((items, index) =>
                    <List>
                        <ListItem button>
                            <ListItemText primary={items.title} secondary={items.description} />
                        </ListItem>
                        <Divider />
                    </List>
                )}
            </Grid>
            {newNote &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom color="textPrimary">
                            New Note
                        </Typography>
                        <TextField
                            fullWidth
                            id='note'
                            multiline
                            autoFocus={newNote}
                            rows="5"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                        <Button variant="outlined" color="primary" onClick={handleEdit}>
                            Save
                        </Button>
                    </Grid>
                </Grid >
            }
        </Grid >
    )
}