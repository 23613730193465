import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  line: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: 'auto',
    left: '50%',
  }
}));

export default function CampaignDetail(props) {
  const classes = useStyles()

  return (
    <Card square elevation={0}>
      <CardContent>
        <Typography className="font-weight-bold" gutterBottom variant="subtitle1">
          {props.title ? props.title : null}
        </Typography>
        <Grid container>
          <Grid container item direction="column" className="pr-3" xs={6}>
            <Typography variant="subtitle1">
              Description
            </Typography>
            <Typography variant="caption">
              {props.detail ? props.detail : "No Description"}
            </Typography>
          </Grid>
          <Divider orientation="vertical" className={classes.line} />
          <Grid direction="column" className="pl-3" container item xs={6}>
            <Grid container className={props.channel ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography variant="subtitle1" className="op-0-6">
                  Channel:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  {props.channel ? props.channel : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.campaign ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography variant="subtitle1" className="op-0-6">
                  Campaign:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  {props.campaign ? props.campaign : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.effective ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography variant="subtitle1" className="op-0-6">
                  Effective:
                  </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  {props.effective ? props.effective : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.expired ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography variant="subtitle1" className="op-0-6">
                  Expired:
                  </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  {props.expired ? props.expired : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={props.system ? "" : "d-none"}>
              <Grid item xs={5}>
                <Typography variant="subtitle1" className="op-0-6">
                  System:
                  </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  {props.system ? props.system : null}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="my-4" justify="center">
          <Button className="mx-2" variant="outlined" color="primary">
            Accept
            </Button>
          <Button className="mx-2" variant="outlined">
            Reject
            </Button>
          <Button className="mx-2" variant="outlined">
            Cancel
            </Button>
        </Grid>
        <Grid container>
          <Grid container item xs={9} direction="column">
            <Typography variant="subtitle1">
              SMS Content
              </Typography>
            <Typography variant="caption">
              {props.sms ? props.sms : "-"}
            </Typography>
          </Grid>
          <Grid container item xs={3} className={props.smsdate ? "flex-column pl-3 " : "flex-column pl-3 d-none"}>
            <Typography variant="subtitle1">
              Date:
              </Typography>
            <Typography variant="subtitle1">
              {props.smsdate ? props.smsdate : null}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
