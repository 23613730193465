import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

export default function Loading() {
  return (
    <div className="p-4">
      <Grid container className="d-flex justify-content-center">
        <Skeleton variant="text" animation="wave" width="60px" height="30px" />
      </Grid>
      <Grid container className="pb-2 d-flex justify-content-center">
        <Skeleton variant="text" animation="wave" width="60px" />
      </Grid>
      <Grid container alignItems="center" className="pb-2">
        <Grid item xs></Grid>
        <Grid item xs className="d-flex justify-content-center pl-2 pr-2">
          <Skeleton
            variant="circle"
            animation="wave"
            width="60px"
            height="60px"
          />
        </Grid>
        <Grid item xs>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="70px"
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" className="pb-2">
        <Grid item xs>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="70px"
          />
        </Grid>
        <Grid item xs className="d-flex justify-content-center pl-2 pr-2">
          <Skeleton
            variant="circle"
            animation="wave"
            width="60px"
            height="60px"
          />
        </Grid>
        <Grid item xs></Grid>
      </Grid>
      <Grid container alignItems="center" className="pb-2">
        <Grid item xs></Grid>
        <Grid item xs className="d-flex justify-content-center pl-2 pr-2">
          <Skeleton
            variant="circle"
            animation="wave"
            width="60px"
            height="60px"
          />
        </Grid>
        <Grid item xs>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="70px"
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" className="pb-2">
        <Grid item xs></Grid>
        <Grid item xs className="d-flex justify-content-center pl-2 pr-2">
          <Skeleton
            variant="circle"
            animation="wave"
            width="60px"
            height="60px"
          />
        </Grid>
        <Grid item xs>
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="70px"
          />
        </Grid>
      </Grid>
    </div>
  );
}
