import React, { Suspense } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CampaignLayout from "./campaign";
import NotificationLayout from "./notification";
import Badge from "@material-ui/core/Badge";
import ReportOutlinedIcon from "@material-ui/icons/ReportOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CollectionsBookmarkOutlinedIcon from "@material-ui/icons/CollectionsBookmarkOutlined";
import LoaddingCampaign from "../loading/campaign";
import LoaddingNotification from "../loading/notification";
import Divider from "@material-ui/core/Divider";

const LazyCampaign = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve(
            import("./index").then(module => ({ default: module.Campaign }))
          ),
        1000
      )
    )
);

const LazyNotification = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve(
            import("./index").then(module => ({ default: module.Notification }))
          ),
        1000
      )
    )
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export function Campaign() {
  return (
    <div>
      {[...Array(3)].map((items, index) =>
        <div key={index}>
          <CampaignLayout
            title="Lucky Egg"
            date="10/01/19"
            time={index < 1 && '15.03.13'}
            detail="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et libero tristique, dictum erat ac, finibus dui. Duis sed tempus neque, quis feugiat nunc."
            channel="SMS"
            campaign="Proactive"
            effective="01/01/2020"
            expired="10/01/2020"
            system="UNI"
            sms="Sed fermentum metus id metus sagittis consequat. In hendrerit a mi eu accumsan."
            smsdate="01/01/2020"
          />
          <Divider />
        </div>
      )}
    </div>
  );
}

export function Notification() {
  return (
    <div>
      <NotificationLayout
        title="Customer Alert"
        description="Mischievous activity"
        // amount="30"
        icon={<ReportOutlinedIcon />}
      />
      <Divider />
      <NotificationLayout
        title="Notification"
        description="Activity updates"
        // amount="55"
        icon={<NotificationsNoneOutlinedIcon />}
      />
      <Divider />
      <NotificationLayout
        title="Complaint"
        description="Treat them carefully"
        // amount="30"
        icon={<CollectionsBookmarkOutlinedIcon />}
      />
    </div>
  );
}

export default function Index() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar className="tabs-header-white" position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label={<Badge badgeContent={3}>Campaign</Badge>}
            {...a11yProps(0)}
          />
          <Tab
            label={<Badge badgeContent={4}>Notification</Badge>}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel className="campaign-tabs" value={value} index={0}>
        <Suspense fallback={<LoaddingCampaign />}>
          <LazyCampaign />
        </Suspense>
      </TabPanel>
      <TabPanel className="notification-tabs" value={value} index={1}>
        <Suspense fallback={<LoaddingNotification />}>
          <LazyNotification />
        </Suspense>
      </TabPanel>
    </div>
  );
}
