import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "antd/dist/antd.css";
import "./style/util.scss";
import "./style/main.scss";
// import "moment/locale/th";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Home from "./page/home";
import Dashboard from "./page/dashboard";
import Notfound from "./component/notfound";
import Schedule from "./component/calendar/schedule";
import { Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./redux/reducers";

const store = createStore(rootReducer);

const THEME = createMuiTheme({
  typography: {
    fontFamily: "Kanit"
  },
  palette: {
    primary: {
      main: "#F4516C"
    },
    secondary: {
      main: "#eeb737"
    },
    darkblue: {
      main: "#2c2d3d"
    }
  }
});

export default class App extends Component {
  render() {
    return (
      <div>
        <ThemeProvider theme={THEME}>
          <Provider store={store}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/s" component={Schedule} />
              <Route component={Notfound} />
            </Switch>
          </Provider>
        </ThemeProvider>
      </div>
    );
  }
}
