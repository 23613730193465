import React from "react";
import "./style.scss";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

const useStyles = makeStyles(theme => ({
    currentPricePlan: {
        backgroundColor: '#373d49',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        textAlign: 'center',
        color: '#FFF',
        paddingTop: 8,
        paddingBottom: 8,
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    }
}));

export default function Offer() {
    const classes = useStyles();
    const [currency, setCurrency] = React.useState("");

    const handleChange = event => {
        setCurrency(event.target.value);
    };

    return (
        <div className="offer p-3">
            <Grid container >
                <Grid item xs={12}>
                    <div className={classes.currentPricePlan}>
                        Customer Information
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent className="py-3">
                            <Grid container direction="row" justify="center">
                                <Grid item xs={12}>
                                    <div className="p-t-20 p-b-20 background-light-gray">
                                        <div className="text-center avatar-user">
                                            <Typography variant="h6">
                                                <Avatar alt="Remy Sharp" src="/assets/images/icon/user_vip@3x.png" />
                                                คุณจิตดี คุณธรรม
                                            </Typography>
                                        </div>
                                        <Typography variant="h5" className="text-center">
                                            083-220-2929
                                        </Typography>
                                        <Grid container direction="row" justify="center" className="text-center">
                                            <Grid item xs={12}><Typography variant="body2" className="text-yellow">
                                                TrueMove H
                                            </Typography></Grid>
                                            <Grid item xs={12}><Typography variant="body2">
                                                4G+ Super Smart 1099
                                            </Typography></Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Typography variant="body1" className="text-center py-4 px-5">
                        Text Any type of activity such as with on-site browsing and conversions are indicators of
                        engagement and therefore play a role in how we score users from Active to At Risk to Churned.
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.root}>
                <Grid container alignItems="center" justify="space-between" spacing={2}>
                    <Grid item xs={4}>
                        <Paper elevation={3} className={classes.paper}>
                            <div className="add-offer">
                                <Typography variant="h6">Add Offer#1</Typography>
                                <Typography variant="body1" className="py-3">
                                    Text Any type of activity such as with on-site browsing and conversions are indicators of
                                    engagement
                                </Typography>
                                <Button variant="outlined" size="large">
                                    <RedeemOutlinedIcon />
                                    <Typography variant="h6">Add Offer</Typography>
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={3} className={classes.paper}>
                            <div className="add-offer">
                                <Typography variant="h6">Add Offer#2</Typography>
                                <Typography variant="body1" className="py-3">
                                    Text Any type of activity such as with on-site browsing and conversions are indicators of
                                    engagement
                                </Typography>
                                <Button variant="outlined" size="large">
                                    <RedeemOutlinedIcon />
                                    <Typography variant="h6">Add Offer</Typography>
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={3} className={classes.paper}>
                            <div className="add-offer">
                                <Typography variant="h6">Add Offer#3</Typography>
                                <Typography variant="body1" className="py-3">
                                    Text Any type of activity such as with on-site browsing and conversions are indicators of
                                    engagement
                                </Typography>
                                <Button variant="outlined" size="large">
                                    <RedeemOutlinedIcon />
                                    <Typography variant="h6">Add Offer</Typography>
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className="w-100 p-t-30">
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">Reject All</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item xs={12} className="pb-3">
                                <Typography variant="body1">
                                    Text Any type of activity such as with on-site browsing and conversions are indicators of
                                    engagement
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    id="outlined-basic"
                                    select
                                    value={currency}
                                    fullWidth
                                    onChange={handleChange}
                                    variant="outlined"
                                >
                                    {currencies.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={5}>
                                <Button variant="contained" size="large" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div >
    );
}
