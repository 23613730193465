import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { PieChart, Pie } from "recharts";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const useStyles = makeStyles(theme => ({
  head: {
    padding: 8
  },
  icon: {
    padding: 10,
    marginRight: "-10px"
  }
}));

function createTableData(name, valueFCR, valueSLA, fill) {
  return { name, valueFCR, valueSLA, fill };
}

function createDonutFCR(name, value, fill) {
  return { name, value, fill };
}

function createDonutSLA(name, value, fill) {
  return { name, value, fill };
}

const tableData = [
  createTableData("Account Maintenance", 5, 10, "#e7bfaa"),
  createTableData("Complaint", 10, 5, "#fc8309"),
  createTableData("Enquiry", 10, 15, "#e8442b"),
  createTableData("Sale", 0, 10, "#f94d6e"),
  createTableData("Service", 50, 0, "#7cf7b5"),
  createTableData("Sugguestion", 5, 10, "#2cc1a4"),
  createTableData("Unmatch", 10, 0, "#4d7e79"),
  createTableData("Retention", 10, 5, "#bfe7ff"),
  createTableData("Verification Welcome Call", 0, 20, "#204589")
];

const donutFCR = [
  createDonutFCR("Account Maintenance", 5, "#e7bfaa"),
  createDonutFCR("Complaint", 10, "#fc8309"),
  createDonutFCR("Enquiry", 10, "#e8442b"),
  createDonutFCR("Sale", 0, "#f94d6e"),
  createDonutFCR("Service", 50, "#7cf7b5"),
  createDonutFCR("Sugguestion", 5, "#2cc1a4"),
  createDonutFCR("Unmatch", 10, "#4d7e79"),
  createDonutFCR("Retention", 10, "#bfe7ff"),
  createDonutFCR("Verification Welcome Call", 0, "#204589")
];

const donutSLA = [
  createDonutSLA("Account Maintenance", 10, "#e7bfaa"),
  createDonutSLA("Complaint", 5, "#fc8309"),
  createDonutSLA("Enquiry", 15, "#e8442b"),
  createDonutSLA("Sale", 10, "#f94d6e"),
  createDonutSLA("Service", 0, "#7cf7b5"),
  createDonutSLA("Sugguestion", 10, "#2cc1a4"),
  createDonutSLA("Unmatch", 0, "#4d7e79"),
  createDonutSLA("Retention", 5, "#bfe7ff"),
  createDonutSLA("Verification Welcome Call", 20, "#204589")
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function Performance() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    console.log("Data is: ");
  });
  return (
    <div>
      <Paper elevation={0}>
        <div
          className={`d-flex justify-content-between align-items-center ${classes.head}`}
        >
          <div className="d-flex align-items-center headbar-wrap">
            <div className="headbar-icon">
              <GradeOutlinedIcon />
            </div>
            <Typography variant="h6" align="center" className="pl-2">
              Score
            </Typography>
          </div>

          <AppBar className="tabs-toggle-grey" position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              <Tab label="Me" {...a11yProps(0)} />
              <Tab label="My Team" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </div>
        <Divider />
      </Paper>
      <TabPanel className="p-0" value={value} index={0}>
        <div className="p-4">
          <Grid container>
            <Grid item xs={7} className="d-flex position-relative">
              <PieChart
                width={250}
                height={250}
                className="d-flex justify-content-center align-items-center h-100"
              >
                <Pie
                  stroke="none"
                  data={donutFCR}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={65}
                  outerRadius={100}
                  paddingAngle={0}
                />
              </PieChart>
              <PieChart
                width={250}
                height={250}
                className="d-flex justify-content-center align-items-center h-100"
              >
                <Pie
                  stroke="none"
                  data={donutSLA}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={65}
                  outerRadius={100}
                  paddingAngle={0}
                />
              </PieChart>
              <Grid
                container
                className="float-on-pie position-absolute w-100 h-100"
              >
                <Grid
                  item
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Typography variant="body1" align="center">
                    FCR RATE
                    <span>75%</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Typography variant="body1" align="center">
                    WITHIN SLA
                    <span>95%</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Table className="donut-table mt-4">
                <TableHead className="mb-2">
                  <TableRow>
                    <TableCell width="60">&nbsp;</TableCell>
                    <TableCell className="border-right" width="44">
                      FCR
                    </TableCell>
                    <TableCell width="44">SLA</TableCell>
                    <TableCell>SR Categories</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map(row => (
                    <TableRow key={row.name}>
                      <TableCell align="right" className="vertical-bottom">
                        <div
                          className="rectangle"
                          style={{ background: row.fill }}
                        ></div>
                      </TableCell>
                      <TableCell className="border-right">
                        {row.valueFCR + "%"}
                      </TableCell>
                      <TableCell>{row.valueSLA + "%"}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container className="pt-4">
            <Grid item xs={4} className="px-1">
              <Card variant="outlined">
                <CardContent className="py-2 px-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Typography variant="h6">Feed Back</Typography>
                      <Typography variant="h4">40</Typography>
                    </div>
                    <AssignmentTurnedInOutlinedIcon className="circle-red" />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className="px-1">
              <Card variant="outlined">
                <CardContent className="py-2 px-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Typography variant="h6">Follow Up</Typography>
                      <Typography variant="h4">40</Typography>
                    </div>
                    <AssignmentTurnedInOutlinedIcon className="circle-blue" />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className="px-1">
              <Card variant="outlined">
                <CardContent className="py-2 px-3">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <Typography variant="h6">ICSAT SCORE</Typography>
                      <Typography variant="h4">3/5</Typography>
                    </div>
                    <Rating
                      className="rating-star pb-1"
                      value={3}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      readOnly
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel className="p-0" value={value} index={1}>
        <div className="p-4">
          <Grid container>
            <Grid item xs={7} className="d-flex position-relative">
              <PieChart
                width={250}
                height={250}
                className="d-flex justify-content-center align-items-center h-100"
              >
                <Pie
                  stroke="none"
                  data={donutFCR}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={65}
                  outerRadius={100}
                  paddingAngle={0}
                />
              </PieChart>
              <PieChart
                width={250}
                height={250}
                className="d-flex justify-content-center align-items-center h-100"
              >
                <Pie
                  stroke="none"
                  data={donutSLA}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={65}
                  outerRadius={100}
                  paddingAngle={0}
                />
              </PieChart>
              <Grid
                container
                className="float-on-pie position-absolute w-100 h-100"
              >
                <Grid
                  item
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Typography variant="body1" align="center">
                    FCR RATE
                    <span>84%</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Typography variant="body1" align="center">
                    WITHIN SLA
                    <span>92%</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Table className="donut-table mt-4">
                <TableHead className="mb-2">
                  <TableRow>
                    <TableCell width="60">&nbsp;</TableCell>
                    <TableCell className="border-right" width="44">
                      FCR
                    </TableCell>
                    <TableCell width="44">SLA</TableCell>
                    <TableCell>SR Categories</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map(row => (
                    <TableRow key={row.name}>
                      <TableCell align="right" className="vertical-bottom">
                        <div
                          className="rectangle"
                          style={{ background: row.fill }}
                        ></div>
                      </TableCell>
                      <TableCell className="border-right">
                        {row.valueFCR + "%"}
                      </TableCell>
                      <TableCell>{row.valueSLA + "%"}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container className="pt-4">
            <Grid item xs={4} className="px-1">
              <Card variant="outlined">
                <CardContent className="py-2 px-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Typography variant="h6">Feed Back</Typography>
                      <Typography variant="h4">45</Typography>
                    </div>
                    <AssignmentTurnedInOutlinedIcon className="circle-red" />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className="px-1">
              <Card variant="outlined">
                <CardContent className="py-2 px-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Typography variant="h6">Follow Up</Typography>
                      <Typography variant="h4">35</Typography>
                    </div>
                    <AssignmentTurnedInOutlinedIcon className="circle-blue" />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className="px-1">
              <Card variant="outlined">
                <CardContent className="py-2 px-3">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <Typography variant="h6">ICSAT SCORE</Typography>
                      <Typography variant="h4">2/5</Typography>
                    </div>
                    <Rating
                      className="rating-star pb-1"
                      value={2}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      readOnly
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
    </div>
  );
}
