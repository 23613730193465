import React from "react";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import AddIcon from '@material-ui/icons/Add';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SRInformation from "./sr-information";
import AcitivityTabs from "./activity-tab";

const useStyles = makeStyles(theme => ({
  search: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    backgroundColor: "#ffffff",
    marginLeft: 0,
    width: "100%",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    width: "auto",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    height: 31
  },
  inputInput: {
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 2, 1, 2),
      width: 55,
      borderRadius: 4,
      cursor: "pointer",
      fontSize: 0,
      "&:focus": {
        padding: theme.spacing(1, 2, 1, 10),
        width: 200,
        borderRadius: 4,
        cursor: "text",
        fontSize: 13
      }
    }
  }
}));

export default function ServiceRequest() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="service-request w-100">
      <Grid container className="p-3">
        <Grid container item xs>
          <Button variant="outlined" className="mr-2 bg-white">
            <AddIcon className="mr-2" />
            New
          </Button>
          <Button variant="outlined" className="mr-2 bg-white">
            <DescriptionOutlinedIcon className="mr-2" />
            Service Request Template
          </Button>
        </Grid>
        <Grid container item xs className="flex-0-0-a">
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon className="ml-2" />
              Search
            </div>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Grid>
      </Grid>
      <div className="px-3 pb-3">
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="px-3"
          >
            <Grid container alignItems="center">
              <Grid item xs={7}>
                <div className="d-flex">
                  <img
                    className="img-fluid app-service"
                    src="/assets/images/icon/method_sr@3x.png"
                    width="60px"
                    alt=""
                  />
                  <div className="p-l-10">
                    <div className="d-flex align-items-center justify-content-center">
                      <Typography variant="h6" className="pr-2 mr-2 border-right">2-239725111852</Typography>
                      <img
                        className="img-fluid"
                        src="/assets/images/icon/true_move@3x.png"
                        width="15px"
                        alt=""
                      />
                      <Typography variant="body1" className="ml-1">096-976-3222</Typography>
                    </div>
                    <Typography variant="body2" className="text-secondary">Category | Subcategory | Issue</Typography>
                    <Typography variant="body2" className="text-secondary">SR Owner | Opendate : 20/01/2020</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="text-center align-items-center">
                  <Typography variant="h6" className="font-weight-bold">22/02/2020</Typography>
                  <Typography variant="body2">Commit Date</Typography>
                </div>
              </Grid>
              <Grid item xs={2} className="text-center align-items-center">
                <img
                  className="img-fluid app-service"
                  src="/assets/images/icon/sr_pending@3x.png"
                  width="80px"
                  alt=""
                />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="px-3">
            <Paper elevation={0} className="w-100">
              <Grid container>
                <Grid container item xs={8} className="pr-2 flex-column">
                  <SRInformation />
                </Grid>
                <Grid container item xs={4} className="flex-column">
                  <AcitivityTabs />
                </Grid>
              </Grid>
            </Paper>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  );
}
