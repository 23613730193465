import React from "react";
import Typography from '@material-ui/core/Typography'

export default function SRShorten() {
    return (
        <>
            <Typography variant="h5">SR&nbsp;Shorten</Typography>
            <Typography>SRShorten</Typography>
        </>
    )
}