import React from "react";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  head: {
    padding: 8
  },
  icon: {
    padding: 10,
    marginRight: "-10px"
  }
}));

export default function Headbar(props) {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <div className={`d-flex justify-content-between align-items-center ${classes.head}`}>
        <div className="d-flex align-items-center headbar-wrap">
          <div className="headbar-icon">
            {props.icon ? props.icon : <InfoOutlinedIcon />}
          </div>
          <Typography variant="h6" align="center" className="pl-2">
            {props.title ? props.title : "Title"}
          </Typography>
        </div>
        {props.button && (
          <IconButton
            className={classes.icon}
            onClick={props.onClick && props.onClick}>
            {props.iconClick ?
              props.iconClick
              :
              <ChevronRightIcon />
            }
          </IconButton>
        )}
      </div>
      <Divider />
    </Paper>
  );
}
