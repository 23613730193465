import React from 'react';
import Card from '@material-ui/core/Card';
import { CardHeader, CardContent, Typography, IconButton } from '@material-ui/core';
import { CardFooter } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

export default function MyCalendar() {
    const [currency, setCurrency] = React.useState('EUR');

    const handleChange = event => {
        setCurrency(event.target.value);
    };

    return (
        <div className="popup-create">
            <Card>
                <CardHeader
                    action={
                        <IconButton aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    }
                    title="Create Note"
                />
                <Divider />
                <CardContent className="pt-4">
                    <Table aria-label="simple table">
                        <TableBody>
                            <Typography className="p-b-20">กรุณาทำรายการ</Typography>
                            <TableRow>
                                <TableCell component="th" scope="row">Event Type: <span className="text-danger">*</span></TableCell>
                                <TableCell>
                                    <TextField
                                        id="filled-basic"
                                        variant="filled"
                                        size="small"
                                        defaultValue="Note"
                                        className="input-textfield small" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Create Date: <span className="text-danger">*</span></TableCell>
                                <TableCell>
                                    <TextField
                                        id="filled-select-currency"
                                        select
                                        value={currency}
                                        onChange={handleChange}
                                        variant="filled"
                                        size="small"
                                        className="input-textfield small"
                                    >
                                        {currencies.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">รายละเอียด: <span className="text-danger">*</span></TableCell>
                                <TableCell>
                                    <TextField
                                        id="filled-multiline-static"
                                        multiline
                                        rows="4"
                                        helperText="(ระบุข้อมูลสูงสุด 255 ตัวอักษร)"
                                        variant="filled"
                                        className="input-textfield"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
                <CardFooter align="right" className="bg-white pb-4">
                    <Button variant="contained" size="medium" color="primary" className="px-5 ml-3">
                        สร้าง
                    </Button>
                    <Button variant="outlined" size="medium" className="px-5 ml-3">ยกเลิก</Button>
                </CardFooter>
            </Card>
        </div>
    )
}