import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Headbar from "../../headbar";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

function createData(id, productType, socOfferName, installDate, effectiveDate, expiryDate, serviceID) {
    return { id, productType, socOfferName, installDate, effectiveDate, expiryDate, serviceID };
}

const rows = [
    createData(1, 'IMIS', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', ''),
    createData(2, 'Discount', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '23523543059093450'),
    createData(3, 'SOC/OFFER', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '23523543059093450'),
    createData(4, 'Discount', 'GPAC3S75-Net 320MB 2 Day_OC-net 620MB 2 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', ''),
    createData(5, 'Discount', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '', '31/01/2020 14:22:30', '31/01/2020 14:22:30', ''),
    createData(6, 'Discount', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '23523543059093450'),
    createData(7, 'IMIS', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '23523543059093450'),
    createData(8, 'Discount', 'GPAC3S75-Net 320MB 2 Day_OC-net 620MB 2 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', ''),
    createData(9, 'IMIS', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '23523543059093450'),
    createData(10, 'SOC/OFFER', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', ''),
    createData(11, 'Discount', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', ''),
    createData(12, 'SOC/OFFER', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '23523543059093450'),
    createData(13, 'Discount', 'GPAC3S75-Net 320MB 1 Day_OC-net 320MB 1 Day', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '31/01/2020 14:22:30', '23523543059093450'),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const headCells = [
    { id: 'productType', numeric: true, disablePadding: false, label: 'PRODUCT TYPE' },
    { id: 'socOfferName', numeric: true, disablePadding: false, label: 'SOC/OFFER NAME' },
    { id: 'installDate', numeric: true, disablePadding: false, label: 'INSTALL DATE' },
    { id: 'effectiveDate', numeric: true, disablePadding: false, label: 'EFFECTIVE DATE' },
    { id: 'expiryDate', numeric: true, disablePadding: false, label: 'EXPIRY DATE' },
    { id: 'serviceID', numeric: true, disablePadding: false, label: 'SERVICE ID' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.head}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    table: {
        minWidth: 1200,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    btnactive: {
        background: '#8F9298',
        color: '#ffffff',
        '&:hover': {
            background: '#8F9298',
            color: '#ffffff',
        }
    },
    labellight: {
        color: '#8F9298',
        borderColor: '#8F9298',
        '&:hover': {
            color: '#8F9298',
        }
    }
}));

export default function MyTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('productType');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [expanded, setExpanded] = React.useState(false);
    const [filter, setFilter] = React.useState('Active');

    const handleClick = panel => event => {
        setExpanded(panel !== expanded ? panel : false)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelect = (value) => e => {
        setFilter(value)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Card square>
                <Headbar icon={props.icon} title={props.headTitle} />
                <CardContent>
                    <div className="d-flex justify-content-end pb-2">
                        <ButtonGroup
                            aria-label="outlined radio button group"
                        >
                            <Button className={filter === 'Active' ? classes.btnactive : classes.labellight} onClick={handleSelect('Active')}>Active</Button>
                            <Button className={filter === 'InActive' ? classes.btnactive : classes.labellight} onClick={handleSelect('InActive')}>InActive</Button>

                        </ButtonGroup>
                    </div>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="medium"
                            aria-label="enhanced table"
                            stickyHeader
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />

                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableBody key={index}>
                                            <TableRow
                                                hover
                                                onClick={handleClick('panel' + row.id)}
                                                tabIndex={-1}
                                            >
                                                <TableCell>{row.productType}</TableCell>
                                                <TableCell>{row.socOfferName}</TableCell>
                                                <TableCell>{row.installDate}</TableCell>
                                                <TableCell>{row.effectiveDate}</TableCell>
                                                <TableCell>{row.expiryDate}</TableCell>
                                                <TableCell>{row.serviceID}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={6} className="p-0 border-0">
                                                    <Collapse in={expanded === ('panel' + row.id) ? true : false} timeout="auto" unmountOnExit>
                                                        <div className="p-3">testtesttesttesttesttest</div>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    );
                                })}
                            <TableBody>
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 48 * emptyRows }}>
                                        <TableCell colSpan={5} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card >
        </div >
    );
}