import React from "react";
// import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import ActivityBox from "./activity-box";
import AttachmentBox from "./attachment";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    flex1: {
        flex: "1",
        "&>.MuiBox-root": {
            padding: 0,
            "&>div:hover": {
                // cursor: "pointer",
                background: "#fafafa"
            }
        }
    }
}));
export default function ServiceBox() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar className="tabs-header" position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab label="Activity" {...a11yProps(0)} />
                    <Tab label="Attach" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.flex1}>
                <ActivityBox
                    type="Notification Error"
                    summary=""
                    detail="Notification Error Detail"
                    status="Done"
                    substatus=""
                    owner="010E1695"
                    ownername="คุณจิตดี คุณธรรม"
                    startdate="29 Mar, 2019 | 15:06:18"
                    enddate="30 Mar, 2019 | 15:06:18"
                    division="True Shop Seacon Square"
                ></ActivityBox>
                <ActivityBox
                    type="Forgot Password"
                    summary=""
                    detail="Forgot Password Detail"
                    status="Done"
                    substatus=""
                    owner="010E1695"
                    ownername="คุณจิตดี คุณธรรม"
                    startdate="29 Mar, 2019 | 15:06:18"
                    enddate="30 Mar, 2019 | 15:06:18"
                    division="True Shop Seacon Square"
                ></ActivityBox>
                <ActivityBox
                    type="Bill Payment"
                    summary=""
                    detail="Bill Payment Detail"
                    status="Done"
                    substatus=""
                    owner="010E1695"
                    ownername="คุณจิตดี คุณธรรม"
                    startdate="29 Mar, 2019 | 15:06:18"
                    enddate="30 Mar, 2019 | 15:06:18"
                    division="True Shop Seacon Square"
                ></ActivityBox>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.flex1}>
                <AttachmentBox />
                <AttachmentBox />
                <AttachmentBox />
            </TabPanel>
        </div>
    );
}
