import React from "react";
import "./style.scss";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import Dialog from "@material-ui/core/Dialog";
import CardMedia from "@material-ui/core/CardMedia";

function SimpleDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <CardMedia
        className="invoice-document"
        image={props.document ? props.document : ""}
        // image="assets/mock/mock_invoice_document.png"
        title="document"
      />
    </Dialog>
  );
}

export default function BillingFullBox(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <div className="w-100 mb-4 position-relative">
      <div className={props.type ? "type-" + props.type : "d-none"}></div>
      <Grid container item xs={12} className="px-4 pt-3 detail-wrap">
        <Typography className="box-header">
          {props.total ? props.total : "-"}
          <span>.{props.point ? props.point : "-"}</span>
        </Typography>
        <Typography className="box-subheader">Payment Amount</Typography>
        <div className="w-100 full-box">
          <Grid container className={props.date ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Date:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.date ? props.date : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.postdate ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Post Date:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.postdate ? props.postdate : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.duedate ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Due Date:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.duedate ? props.duedate : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.channel ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Channel:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.channel ? props.channel : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.level ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Level:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.level ? props.level : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.description ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Description:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.description ? props.description : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.reason ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Reason:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.reason ? props.reason : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.amount ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Amount:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.amount ? props.amount : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.payment ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Payment:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.payment ? props.payment : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.adjust ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Adjust:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.adjust ? props.adjust : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.line1 ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Line1:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.line1 ? props.line1 : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={props.status ? "" : "d-none"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Status:
              </Typography>
            </Grid>
            <Grid item xs={8} className="pl-4">
              <Typography variant="body2" className="font-weight-bold">
                {props.status ? props.status : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Button
            className={props.document ? "px-4 mt-4 mb-2" : "d-none"}
            startIcon={<DescriptionIcon />}
            onClick={handleClickOpen}
          >
            View Invoice
          </Button>
          <SimpleDialog
            open={open}
            onClose={handleClose}
            document={props.document ? props.document : ""}
          />
        </div>
      </Grid>
    </div>
  );
}
