import React from 'react'
import Grid from "@material-ui/core/Grid";
import Skeleton from '@material-ui/lab/Skeleton';

export default function Loading() {
  return (
    <div>
      <Skeleton variant="text" animation="wave" width="50%" height={25} />
      <Grid container alignItems="center" className="pt-2">
        <Skeleton variant="rect" animation="wave" width="10%" height={20} className="mr-2" />
        <Skeleton variant="text" animation="wave" width="60%" />
      </Grid>
      <Grid container className="pt-2">
        <Grid item xs={6}>
          <Skeleton variant="text" animation="wave" width="60%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" animation="wave" width="70%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" animation="wave" width="40%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" animation="wave" width="90%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" animation="wave" width="70%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" animation="wave" width="40%" />
        </Grid>
      </Grid>
      <Grid container className="pt-2">
        <Skeleton variant="rect" animation="wave" width="100%" height={100} />
      </Grid>
    </div>
  )
}