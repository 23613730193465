import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { th } from 'date-fns/locale'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    }
}));

export default function FinalCharge() {
    const classes = useStyles()
    const [selectedDate, handleDateChange] = React.useState(new Date());

    return (
        <div className={classes.root}>
            <Card>
                <CardContent>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid container item alignItems="center" xs={4}>
                            <Avatar>
                                <PaymentOutlinedIcon />
                            </Avatar>
                            <Typography className="pl-3">
                                Final Charge
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h4" align="right">
                                2,3042.45 บาท
                            </Typography>
                        </Grid>
                        <Grid container item alignItems="center" xs={4}>
                            <Avatar>
                                <DateRangeOutlinedIcon />
                            </Avatar>
                            <Typography className="pl-3">
                                Prefer Date
                            </Typography>
                        </Grid>
                        <Grid container item xs={8} justify="flex-end">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={th}>
                                <KeyboardDatePicker
                                    clearable
                                    value={selectedDate}
                                    placeholder="ตัวอย่าง : 20/09/2020"
                                    onChange={date => handleDateChange(date)}
                                    minDate={new Date()}
                                    format="dd/MM/yyyy"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}