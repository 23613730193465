import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Events from "./events";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button'
import Schedule from "./schedule.js";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Dialog from '@material-ui/core/Dialog';
import Popup from './popup.js'

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  head: {
    padding: 8
  },
  border: {
    borderRight: "1px solid #dee2e6",
    [theme.breakpoints.down("md")]: {
      borderRight: 0
    }
  },
  calendar: {
    padding: 0
  },
  button: {
    position: 'absolute',
    right: 25,
    marginTop: '-10px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function MyCalendar() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Grid container spacing={2} className="my-3">
        <Grid item xs={12} lg={4} className={classes.border}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Reminders</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="h6" align="center">
                      Today
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      gutterBottom
                      className="pt-3"
                    >
                      2
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="h6" align="center">
                      This Week
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      gutterBottom
                      className="pt-3"
                    >
                      10
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="h6">All Reminders</Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      gutterBottom
                      className="pt-3"
                    >
                      70
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Note
              </Typography>
              <List>
                <ListItem button>
                  <ListItemText
                    primary="17/02/2020"
                    secondary="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText
                    primary="18/02/2020"
                    secondary="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText
                    primary="19/02/2020"
                    secondary="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText
                    primary="20/02/2020"
                    secondary="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Paper elevation={0}>
            <div
              className={`d-flex justify-content-between align-items-center ${classes.head}`}
            >
              <div className="d-flex align-items-center headbar-wrap">
                <div className="headbar-icon">
                  <DateRangeOutlinedIcon />
                </div>
                <Typography variant="h6" align="center" className="pl-2">
                  Calendar
                </Typography>
              </div>
              <AppBar className="tabs-toggle-grey" position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                >
                  <Tab label="Carlendar" {...a11yProps(0)} />
                  <Tab label="Schedule" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
            </div>
            <Divider className="mb-4 mt-2" />
          </Paper>
          <TabPanel value={value} index={0}>
            <div className={classes.button}>
              <Button variant="outlined" className="mr-2" onClick={handleClickOpen}>
                <div className="d-flex align-items-end">
                  <AddOutlinedIcon />
                  <div className="pl-2">
                    Add Event
                </div>
                </div>
              </Button>
              <Button variant="outlined">
                Today
            </Button>
            </div>
            <Calendar
              events={Events}
              startAccessor="start"
              endAccessor="end"
              defaultDate={moment().toDate()}
              localizer={localizer}
              views={false}
              style={{ height: 600 }}
              className={classes.calendar}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={classes.button}>
              <Button variant="outlined" className="mr-2">
                <div className="d-flex align-items-end">
                  <AddOutlinedIcon />
                  <div className="pl-2">
                    Add Event
                </div>
                </div>
              </Button>
              <Button variant="outlined">
                Today
              </Button>
            </div>
            <Calendar
              events={Events}
              startAccessor="start"
              endAccessor="end"
              defaultDate={moment().toDate()}
              localizer={localizer}
              views={false}
              className={[classes.calendar, 'schedule'].join(' ')}
            />
            <Schedule />
          </TabPanel>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        fullWidth>
        <Popup />
      </Dialog>
    </div >
  );
}