import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Menubar from '../../component/menubar'
import Note from '../../component/note'
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MyTask from './../../component/my-task'
import Activity from './../../component/activity'
import Calendar from './../../component/calendar'
import Performance from './../../component/performance'
import MyTeam from './../../component/my-team'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: 47,
    },
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(1),
        boxShadow: "none"
    },
}));

export default function Home(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Menubar {...props} />
            <Container maxWidth={false} disableGutters className={classes.container}>
                <div className={classes.root}>
                    <Card square elevation={0}>
                        <CardContent>
                            <AppBar className="tabs-header" position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab label="My Task" {...a11yProps(0)} />
                                    <Tab label="Activity" {...a11yProps(1)} />
                                    <Tab label="Calendar" {...a11yProps(2)} />
                                    <Tab label="Performance" {...a11yProps(3)} />
                                    <Tab label="My Team" {...a11yProps(4)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <MyTask />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Activity />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Calendar />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Performance />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <MyTeam />
                            </TabPanel>
                        </CardContent>
                    </Card>
                </div>
            </Container>
            <Note />
        </div>
    );
}
