import React, { Suspense } from "react";
import "./style.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from '@material-ui/core/Chip';
import Headbar from "./../headbar";
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Loading from './../loading/ivr-call-flow'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const LazyIvrCallFlow = React.lazy(() =>
    new Promise((resolve, reject) =>
        setTimeout(() => resolve(
            import("./index").then(module => ({ default: module.IVRCallFlow }))
        ), 3000)
    )
);

export function IVRCallFlow() {
    return (
        <div>
            <Grid container justify="space-between">
                <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                        IVR Time 11 Min
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body2" color="textSecondary" className="text-right">
                        A Number : 09xxxxxxxx
                    </Typography>
                </Grid>
            </Grid>
            <div className="p-t-30 p-b-5 text-center">
                <Grid container justify="space-between">
                    <Grid item xs={2}>
                        <Chip label="Creeding" className="w-100 first-flow" />
                    </Grid>
                    <Grid item xs={1}>
                        <div className="flow-line"><div className="flow-arrow"></div></div>
                    </Grid>

                    <Grid item xs={9}>
                        <Grid container direction="row" className="p-b-30">
                            <Grid item xs={2}>
                                <Chip label="TMH | 09xxx" variant="outlined" className="w-100" />
                            </Grid>
                            <Grid item xs={1}>
                                <div className="flow-line"><div className="flow-arrow"></div></div>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip label="Enquiry" variant="outlined" className="w-100" />
                            </Grid>
                            <Grid item xs={1}>
                                <div className="flow-line"><div className="flow-arrow"></div></div>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip label="TOL | 96xxx" variant="outlined" className="w-100" />
                            </Grid>
                            <Grid item xs={1}>
                                <div className="flow-line"><div className="flow-arrow"></div></div>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip label="Promotion" variant="outlined" className="w-100" />
                                <div className="flow-line-downleftdown">
                                    <div className="downleftdown-item-ver"></div>
                                    <div className="downleftdown-item-hor lg"></div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" className="p-b-30">
                            <Grid item xs={2}>
                                <Chip label="Up Speed" variant="outlined" className="w-100" />
                            </Grid>
                            <Grid item xs={1}>
                                <div className="flow-line"><div className="flow-arrow"></div></div>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip label="Birthday" variant="outlined" className="w-100" />
                            </Grid>
                            <Grid item xs={1}>
                                <div className="flow-line"><div className="flow-arrow"></div></div>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip label="Privilege" variant="outlined" className="w-100" />
                            </Grid>
                            <Grid item xs={1}>
                                <div className="flow-line"><div className="flow-arrow"></div></div>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip label="ติดต่อเจ้าหน้าที่" variant="outlined" className="w-100" />
                                <div className="flow-line-downleftdown">
                                    <div className="downleftdown-item-ver"></div>
                                    <div className="downleftdown-item-hor lg"></div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" className="p-b-30">
                            <Grid item xs={2}>
                                <Chip label="Up Speed" variant="outlined" className="w-100" />
                            </Grid>
                            <Grid item xs={1}>
                                <div className="flow-line"><div className="flow-arrow"></div></div>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip label="Birthday" variant="outlined" className="w-100" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default function Index() {
    const [remove, setRemove] = React.useState(false)

    const handleRemove = () => {
        setRemove(true)
    }

    return (
        <div className={remove ? 'd-none' : 'ivr-call-flow'}>
            <Card square>
                <Headbar
                    icon={<CallOutlinedIcon />}
                    iconClick={<CloseOutlinedIcon />}
                    title="IVR Call Flow"
                    button
                    onClick={handleRemove} />
                <CardContent>
                    <Suspense fallback={<Loading />}>
                        <LazyIvrCallFlow />
                    </Suspense>
                </CardContent>
            </Card>
        </div>
    )
}