import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import muiListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const ListItemText = withStyles(theme => ({
    primary: {
        color: theme.palette.common.black
    },
    secondary: {
        color: theme.palette.secondary.main
    }
}))(muiListItemText);


export default function ProfileContact(props) {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            <ListItem button onClick={() => props.handleAddTab("0998887765")}>
                <ListItemAvatar>
                    <img src="assets/images/icon/true_move@3x.png" width="40px" alt="" />
                </ListItemAvatar>
                <ListItemText primary="0969763232" secondary="TrueMove H" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => props.handleAddTab("0566665454")}>
                <ListItemAvatar>
                    <img src="assets/images/icon/true_move@3x.png" width="40px" alt="" />
                </ListItemAvatar>
                <ListItemText primary="099288888" secondary="TrueMove H" />
            </ListItem>
        </List>
    );
}