import React from 'react'
import { Link } from 'react-router-dom'
import MoodBadOutlinedIcon from "@material-ui/icons/MoodBadOutlined";

export default function Notfound() {
    return (
        <div className="height-fullscreen">
            <div className="ab-c-m text-center">
                <MoodBadOutlinedIcon color="primary" style={{ fontSize: 60 }} />
                <h1 className="py-4">404</h1>
                <h5 className="pb-4 text-muted">Page not found</h5>
                <p>This Page you are looking fot doesn't exist or an other error occurred.</p>
                <p><Link to="/">Go back</Link>, or head over to <Link to="/" >true-crm.com</Link> to choose a new direction.</p>
            </div>
        </div>
    )
}
