import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { IconButton } from "@material-ui/core";

export default function SRInformation() {
    return (
        <div>
            <div className="p-b-20">
                <Typography variant="h6" className="d-inline-block">
                    SR Information
                        </Typography>
                <IconButton className="p-0 ml-2">
                    <img
                        className="img-fluid d-inline-block"
                        src="/assets/images/icon/table_edit@3x.png"
                        width="20px"
                        alt=""
                    />
                </IconButton>
            </div>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Product Line" defaultValue="True Mobile" size="small" variant="outlined" className="w-100" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Product Type" variant="outlined" className="w-100" defaultValue="Prepay" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Product" variant="outlined" className="w-100" defaultValue="True Mobile Prepay" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Service ID" variant="outlined" className="w-100" defaultValue="096-976-3222" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Billing Acc. Sub Type" variant="outlined" className="w-100" defaultValue="EMP.: True Employee" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Source" variant="outlined" className="w-100" defaultValue="-" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="SR#" variant="outlined" className="w-100" defaultValue="2-239725111836" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Case ID" variant="outlined" className="w-100" defaultValue="149-665419" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Category" variant="outlined" className="w-100" defaultValue="Enquiry" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Sub Category" variant="outlined" className="w-100" defaultValue="โปรโมชั่น/แพคเกจ" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Issue" variant="outlined" className="w-100" defaultValue="รายละเอียด/การสมัคร" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Case Type" variant="outlined" className="w-100" defaultValue="-" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Resolution" variant="outlined" className="w-100" defaultValue="-" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Reason" variant="outlined" className="w-100" defaultValue="-" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Sub Reason" variant="outlined" className="w-100" defaultValue="-" size="small" />
                </Grid>
            </Grid>

            <div className="mb-4 ant-divider-dashed"></div>

            <div className="p-b-20">
                <Typography variant="h6" className="d-inline-block">
                    Status and Ownership
                        </Typography>
                <IconButton className="p-0 ml-2">
                    <img
                        className="img-fluid d-inline-block"
                        src="/assets/images/icon/table_edit@3x.png"
                        width="20px"
                        alt=""
                    />
                </IconButton>
            </div>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Status" defaultValue="Open" size="small" variant="outlined" className="w-100" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Sub Status" variant="outlined" className="w-100" defaultValue="-" size="small"
                    />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Account" variant="outlined" className="w-100" defaultValue="สบายใจ สุดขีด" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Account Type" variant="outlined" className="w-100" defaultValue="Individual" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Account Sub Type" variant="outlined" className="w-100" defaultValue="Mass" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Contact Name" variant="outlined" className="w-100" defaultValue="สบายใจ สุดขีด" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Contact Phone" variant="outlined" className="w-100" defaultValue="096-976-3222" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Owner" variant="outlined" className="w-100" defaultValue="10174532" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Owner Name" variant="outlined" className="w-100" defaultValue="Varunee Namra" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Owner Division" variant="outlined" className="w-100" defaultValue="PM Team" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Create Division" variant="outlined" className="w-100" defaultValue="PM Team" size="small" />
                </Grid>
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Opened" variant="outlined" className="w-100" defaultValue="13 May, 2019 | 01:58" size="small" />
                </Grid>
            </Grid>
            <Grid container className="pb-4">
                <Grid container item xs={4} className="pr-2">
                    <TextField label="Committed" variant="outlined" className="w-100" defaultValue="20 May, 2019 | 01:58" size="small" />
                </Grid>
            </Grid>
        </div>
    );
}