import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

export default function Loading() {
  return (
    <div className="service-request w-100">
      <Grid container className="p-3">
        <Grid container item xs>
          <Skeleton
            variant="rect"
            animation="wave"
            width="82px"
            height="35px"
            className="mr-2"
          />
          <Skeleton
            variant="rect"
            animation="wave"
            width="210px"
            height="35px"
            className="mr-2"
          />
        </Grid>
        <Grid container item xs className="flex-0-0-a">
          <Skeleton
            variant="rect"
            animation="wave"
            width="210px"
            height="35px"
            className="ml-2"
          />
        </Grid>
      </Grid>
      <div className="px-3 pb-3">
        <Skeleton
          variant="rect"
          animation="wave"
          width="100%"
          height="98px"
        />
      </div>
    </div>
  );
}
