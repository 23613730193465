import React from "react";
import "./style.scss";
import CampaignDetail from "./detail.js";
import Card from "@material-ui/core/Card";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default function Campaign(props) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Card className="campaign-notification" square elevation={0}>
      <div className="campaign-card">
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{ disablePortal: true }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              placement="left-start"
              title={
                <CampaignDetail
                  title={props.title ? props.title : null}
                  detail={props.detail ? props.detail : null}
                  channel={props.channel ? props.channel : null}
                  campaign={props.campaign ? props.campaign : null}
                  effective={props.effective ? props.effective : null}
                  expired={props.expired ? props.expired : null}
                  system={props.system ? props.system : null}
                  sms={props.sms ? props.sms : null}
                  smsdate={props.smsdate ? props.smsdate : null}
                />
              }
            >
              <CardActionArea onClick={handleTooltipOpen}>
                <CardContent>
                  <Grid container>
                    <Grid container item className="flex-column" xs>
                      <Typography className="font-weight-bold campaign-name" variant="subtitle1">
                        {props.title ? props.title : null}
                      </Typography>
                    </Grid>
                    <Grid container item xs className="justify-content-end">
                      <Typography variant="subtitle1" component="p">
                        {props.date ? props.date : null}
                      </Typography>
                      <Typography className={props.time ? "ml-2" : ""} variant="subtitle1">
                        {props.time ? props.time : null}
                      </Typography>
                    </Grid>
                    <Typography variant="caption" className="max-3-line">
                      {props.detail ? props.detail : null}
                    </Typography>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
    </Card>
  );
}
