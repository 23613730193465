import React from "react";
import "./style.scss";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";

export default function SingleSummary() {
  const data = [
    { name: "Jan", THB: 299 },
    { name: "Feb", THB: 459 },
    { name: "Mar", THB: 504 },
    { name: "Apr", THB: 440 },
    { name: "May", THB: 700 },
    { name: "June", THB: 1127 }
  ];

  return (
    <div>
      <LineChart width={600} height={200} data={data}>
        <CartesianGrid stroke="#f2f3f8" />
        <Line type="monotone" dataKey="THB" stroke="#F4516C" />
        <XAxis dataKey="name" />
        <YAxis />
      </LineChart>
      <Grid container className="pb-2">
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Outstanding Balance:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            1214.02
          </Typography>
        </Grid>
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Available Credit / Credit limit:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            8785.98 / (Permanent) 10,000
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Current Bill Balance:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            587.96
          </Typography>
        </Grid>
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Last Payment Amount (Actual Amount):
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            218.66
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Overdue Amount:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            626.06
          </Typography>
        </Grid>
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Last Payment Date:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            20/11/2019
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Due Date:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            21/01/2020
          </Typography>
        </Grid>
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Last Payment Channel (Source Description):
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            True Money - Echannels
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Current Bill Cycle:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            28/27
          </Typography>
        </Grid>
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Last Payment Method:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            Cash
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            Bill Format:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            Paper
          </Typography>
        </Grid>
        <Grid container item xs={3}>
          <Typography variant="body1" component="p" className="op-0-6">
            EText Media:
          </Typography>
        </Grid>
        <Grid container item xs={3} className="pl-2">
          <Typography variant="body1" component="p">
            10971944150
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
