import React from "react";
import Grid from "@material-ui/core/Grid";
import CustomerProfile from "../../component/customer-profile";
import AssetProfile from "../../component/asset-profile";
import PendingTask from "../../component/pendingtask";
import SummaryBox from "../../component/summarybox";
import AfterSaleService from "../../component/after-sale-service";
import CampaignNotification from "../../component/campaign-notification";
import Knowledge from "../../component/knowledge";
import Details from "../../component/details";
import Contact from "../../component/contact";
import IVRCallFlow from "../../component/ivr-call-flow";

export default function Content(props) {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={3} className="pl-2">
          <div className="mt-2">
            <CustomerProfile {...props} />
          </div>
          <div className="mt-2">
            <AssetProfile {...props} />
          </div>
        </Grid>
        <Grid item xs={12} lg={7} className="pl-2">
          <div className="mt-2">
            <IVRCallFlow />
          </div>
          <div className="mt-2">
            <SummaryBox />
          </div>
          <div className="mt-2">
            <Details />
          </div>
        </Grid>
        <Grid item xs={12} lg={2} className="px-2">
          <div className="mt-2">
            <PendingTask />
          </div>
          <div className="mt-2">
            <CampaignNotification />
          </div>
          <div className="mt-2">
            <Contact />
          </div>
          <div className="mt-2">
            <Knowledge />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="px-2">
          <div className="my-2">
            <AfterSaleService />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="px-2">
          <div className="mt-5 mb-4 text-center" style={{ color: '#adadba' }}>
            2020 © True CRM
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
