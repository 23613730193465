import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    skeBorderRad: {
        borderRadius: '40px',
    },
}));

export default function IVRCallFlow() {
    const classes = useStyles();

    return (
        <div>
            <Grid container justify="space-between">
                <Grid item xs>
                    <Skeleton variant="text" animation="wave" width="30%" />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="text" animation="wave" width="30%" className="ml-auto" />
                </Grid>
            </Grid>
            <div className="p-t-30 p-b-5 text-center">
                <Grid container justify="space-between">
                    <Grid item xs={2}>
                        <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                    </Grid>
                    <Grid item xs={1}></Grid>

                    <Grid item xs={9}>
                        <Grid container direction="row" className="p-b-30">
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" className="p-b-30">
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" className="p-b-30">
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="rect" animation="wave" width="100%" height={32} className={classes.skeBorderRad} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
