import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import './style.scss'
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { Switch } from 'antd';

const useStyles = makeStyles(theme => ({
    contentCenter: {
        padding: theme.spacing(2, 0),
    },
    row: {
        padding: theme.spacing(2),
        borderRight: '1px solid #dee2e6',
        [theme.breakpoints.down('md')]: {
            borderRight: 0,
        },
        '&:last-child': {
            borderRight: 0,
        },
    },
    margin: {
        margin: theme.spacing(0),
    },
    currentPricePlan: {
        backgroundColor: theme.palette.darkblue.main,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        textAlign: 'center',
        color: '#FFF',
        paddingTop: 8,
        paddingBottom: 8,
    },
}));

const BorderLinearProgress = withStyles({
    root: {
        height: 12,
    },
    bar: {
        backgroundColor: '#F4516C',
    }
})(LinearProgress);

export default function Usage3Months() {
    const classes = useStyles();
    const [data] = React.useState([
        {
            icon: '/assets/images/icon/tm_voice@3x.png',
            title: 'Voice (min)',
            count: 270,
            unit: 'Average per month',
            use: [
                {
                    month: 'oct',
                    persent: 70,
                    number: 200,
                    total: 300,
                    limit: false
                },
                {
                    month: 'nov',
                    persent: 30,
                    number: 300,
                    total: 300,
                    limit: false
                },
                {
                    month: 'dec',
                    persent: 80,
                    number: 280,
                    total: 300,
                    limit: true
                }
            ]
        },
        {
            icon: '/assets/images/icon/tm_data@3x.png',
            title: 'DATA (GB)',
            count: 25,
            unit: 'Average per month',
            use: [
                {
                    month: 'oct',
                    persent: 70,
                    number: 200,
                    total: 300,
                    limit: false
                },
                {
                    month: 'nov',
                    persent: 10,
                    number: 300,
                    total: 300,
                    limit: false
                },
                {
                    month: 'dec',
                    persent: 80,
                    number: 280,
                    total: 300,
                    limit: true
                }
            ]
        },
        {
            icon: '/assets/images/icon/tm_wifi@3x.png',
            title: 'SMS (message)',
            count: 5,
            unit: 'Average per month',
            use: [
                {
                    month: 'oct',
                    persent: 70,
                    number: 200,
                    total: 300,
                    limit: false
                },
                {
                    month: 'nov',
                    persent: 100,
                    number: 300,
                    total: 300,
                    limit: false
                },
                {
                    month: 'dec',
                    persent: 80,
                    number: 280,
                    total: 300,
                    limit: true
                }
            ]
        }
    ])

    return (
        <div>
            <Grid container>
                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} className="pt-4">
                    <Typography variant="body2" className="pr-2">
                        Mobile No.
                    </Typography>
                    <Switch defaultChecked />
                    <Typography variant="body2" className="pl-2">
                        Ban
                    </Typography>
                </Grid>

                <Grid container className={classes.contentCenter}>
                    {data.map((items, index) =>
                        <Grid key={index} item xs={12} lg={4} className={classes.row}>
                            <div className="d-flex pb-3">
                                <div>
                                    <img className="ing-fluid" src={items.icon} width="40px" alt="" />
                                </div>
                                <div className="pl-3">
                                    <h5>{items.title}</h5>
                                    <h2 className="text-main">{items.count}</h2>
                                    <p className="op-0-5">{items.unit}</p>
                                </div>
                            </div>
                            {items.use.map((items, index) =>
                                <Grid key={index} container alignItems="center" className="pr-4">
                                    <Grid item xs={2} className="text-right">
                                        <span>{items.month}</span>
                                    </Grid>
                                    <Grid item xs={8} className="px-2">
                                        <BorderLinearProgress
                                            className={classes.margin}
                                            variant="determinate"
                                            color="primary"
                                            value={items.persent} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography color={items.limit ? 'primary' : 'initial'} variant="body2">{items.number}/{items.total}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                    <div className={classes.currentPricePlan}>
                        Current Price Plan
                    </div>
                </Grid>
                <Grid item xs={4} className="p-3 border-right">
                    <Grid container direction="row" alignItems="center" justify="center">
                        <Typography variant="h6">
                            650
                        </Typography>
                        <Typography variant="body2" className="pl-2">
                            min
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={4} className="p-3 border-right">
                    <Grid container alignItems="center" justify="center">
                        <Typography variant="h6">
                            16.0
                        </Typography>
                        <Typography variant="body2" className="pl-2">
                            GB
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" justify="center">
                        <Typography variant="body2" className="pl-2 op-0-5">
                            Max Speed
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={4} className="p-3">
                    <Grid container alignItems="center" justify="center">
                        <Typography variant="h6">
                            3
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}