import React from "react";
import "./style.scss";
import Card from "@material-ui/core/Card";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

export default function Expansion(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { handleAddTab } = props

    return (
        <div>
            <Card square elevation={0}>
                <AppBar className="tabs-header" position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Active" {...a11yProps(0)} />
                        <Tab label="In Active" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <List component="nav">
                        <ListItem button onClick={handleAddTab("0810090000")}>
                            <ListItemIcon>
                                <img className="img-fluid" src="/assets/images/icon/true_move@3x.png" width="19px" alt="" />
                            </ListItemIcon>
                            <ListItemText primary="0810090000" />
                        </ListItem>
                        <ListItem button onClick={handleAddTab("0848389393")}>
                            <ListItemIcon>
                                <img className="img-fluid" src="/assets/images/icon/true_move@3x.png" width="19px" alt="" />
                            </ListItemIcon>
                            <ListItemText primary="0848389393" />
                        </ListItem>
                    </List>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <List component="nav">
                        <ListItem button onClick={handleAddTab("0813330001")}>
                            <ListItemIcon>
                                <img className="img-fluid" src="/assets/images/icon/true_move@3x.png" width="19px" alt="" />
                            </ListItemIcon>
                            <ListItemText primary="0813330001" />
                        </ListItem>
                        <ListItem button onClick={handleAddTab("0842222222")}>
                            <ListItemIcon>
                                <img className="img-fluid" src="/assets/images/icon/true_move@3x.png" width="19px" alt="" />
                            </ListItemIcon>
                            <ListItemText primary="0842222222" />
                        </ListItem>
                    </List>
                </TabPanel>
            </Card>
        </div>
    )
}