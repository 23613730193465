import React from "react";
import "./style.scss";
import "./../style.scss";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ActivityTab from "../../details/service-request/activity-tab";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default function Notification(props) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className="noti-detail">
      <Card className="campaign-notification" square elevation={0}>
        <div className="campaign-card">
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                placement="left-start"
                title={<ActivityTab />} >
                <CardActionArea onClick={handleTooltipOpen}>
                  <CardContent>
                    <Grid className="noti-card-wrap" container alignItems="center">
                      <Grid container item xs={2}>
                        {props.icon ? props.icon : <ErrorOutlineIcon />}
                      </Grid>
                      <Grid container item className="px-3" xs>
                        <Typography variant="h6" component="p">
                          {props.title ? props.title : "No Title"}
                        </Typography>
                        <Typography variant="caption" component="p">
                          {props.description ? props.description : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
      </Card>
    </div>
  )
}
