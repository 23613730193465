import React from "react";
import "./style.scss";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Divider, Button } from "@material-ui/core";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import cliTruncate from "cli-truncate";

const options = ["Recent", "Yesterday", "Tomorrow"];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  titlebill: {
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px"
  },
  rootbutton: {
    display: "inline-block",
    flexDirection: "column",
    alignItems: "center"
  },
  popper: {
    zIndex: "2"
  },
  btnactive: {
    background: "#8F9298",
    color: "#ffffff!important",
    "&:hover": {
      background: "#8F9298",
      color: "#ffffff!important"
    }
  },
  labellight: {
    color: "#8F9298",
    borderColor: "#8F9298",
    "&:hover": {
      color: "#8F9298"
    }
  }
}));

export default function BillingCustomer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="billing-customer m-4">
      <Paper elevation={3}>
        <div className={classes.titlebill}>
          <Typography variant="body1" className="text-white text-center">
            Billing
          </Typography>
        </div>
        <Grid container className={classes.paper}>
          <Grid item xs={6}>
            <div>
              <div>
                <Typography variant="h6" className="d-inline-block">
                  Billing Profile
                </Typography>
                <img
                  className="img-fluid ml-2"
                  src="/assets/images/icon/table_edit@3x.png"
                  width="20px"
                  alt=""
                />
              </div>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Typography variant="caption">Billing Name:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <div className={classes.billproinfo}>
                    <Typography variant="caption" className="d-block">
                      คุณจิตดี คุณธรรม
                    </Typography>
                    <Typography variant="caption">
                      {cliTruncate(
                        "บ้านเลขที่ 198/78, หมู่ 13, อาคาร/หมู่บ้านไลน์ กรุงเทพฯ",
                        50,
                        { position: "middle", preferTruncationOnSpace: true }
                      )}
                      <img
                        className="img-fluid ml-1"
                        src="/assets/images/icon/info@3x.png"
                        width="20px"
                        alt=""
                      />
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Typography variant="caption">Receipt Name:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <div>
                    <Typography variant="caption" className="d-block">
                      คุณจิตดี คุณธรรม
                    </Typography>
                    <Typography variant="caption">
                      {cliTruncate(
                        "บ้านเลขที่ 198/78, หมู่ 13, อาคาร/หมู่บ้านไลน์ กรุงเทพฯ",
                        50,
                        { position: "middle", preferTruncationOnSpace: true }
                      )}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <div>
                <Typography variant="h6" className="d-inline-block">
                  Payment Profile
                </Typography>
                <img
                  className="img-fluid ml-2"
                  src="/assets/images/icon/table_edit@3x.png"
                  width="20px"
                  alt=""
                />
              </div>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Typography variant="caption">Tax ID:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <div>
                    <Typography variant="caption">0001111222843</Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Typography variant="caption">Branch No:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <div>
                    <Typography variant="caption">00001</Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Typography variant="caption">Payment Method</Typography>
                </Grid>
                <Grid item xs={9}>
                  <div>
                    <Typography variant="caption" className="d-block">
                      <CreditCardOutlinedIcon className="mr-1" />
                      Direct debit through credit card:
                      <img
                        className="img-fluid ml-1"
                        src="/assets/images/icon/info@3x.png"
                        width="20px"
                        alt=""
                      />
                    </Typography>
                    <Typography variant="caption">
                      CITIBANK | Card No.: 1234-56xx-xxxx-8880
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Divider className="ml-4 mr-4" />
        <Grid container className={classes.paper}>
          <Grid item xs={12}>
            <div className="text-right">
              <div className={classes.rootbutton}>
                <ButtonGroup aria-label="outlined radio button group">
                  <Button
                    className={[
                      classes.labellight,
                      props.selected === 1 ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelect(1)}
                  >
                    All
                  </Button>
                  <Button
                    className={[
                      classes.labellight,
                      props.selected === 2 ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelect(2)}
                  >
                    TMH
                  </Button>
                  <Button
                    className={[
                      classes.labellight,
                      props.selected === 3 ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelect(3)}
                  >
                    TOL
                  </Button>
                  <Button
                    className={[
                      classes.labellight,
                      props.selected === 4 ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelect(4)}
                  >
                    TVS
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Grid>
        </Grid>
        <Divider className="ml-4 mr-4" />
        <Grid container className={classes.paper}>
          <Grid item xs={2}>
            <Button variant="outlined">
              <PrintOutlinedIcon className="mr-1" />
              Reprint
            </Button>
          </Grid>
          <Grid item xs={10}>
            <div className="text-right">
              <div className="mr-1 d-inline-block">
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef}
                  aria-label="outlined split button"
                >
                  <Button
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="Recent"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    className={classes.labellight}
                  >
                    {options[selectedIndex]}
                    <ArrowDropDownIcon />
                  </Button>
                  <Button onClick={handleClick} className={classes.labellight}>
                    Today
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  className={classes.popper}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={event =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <div className={classes.rootbutton}>
                <ButtonGroup aria-label="outlined radio button group">
                  <Button
                    className={[
                      classes.labellight,
                      props.selectTypeA === true ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelectTypeA(true)}
                  >
                    All
                  </Button>
                  <Button
                    className={[
                      classes.labellight,
                      props.selectTypeB === true ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelectTypeB(true)}
                  >
                    Payment
                  </Button>
                  <Button
                    className={[
                      classes.labellight,
                      props.selectTypeC === true ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelectTypeC(true)}
                  >
                    Invoice
                  </Button>
                  <Button
                    className={[
                      classes.labellight,
                      props.selectTypeD === true ? classes.btnactive : ""
                    ].join(" ")}
                    onClick={() => props.handleSelectTypeD(true)}
                  >
                    Adjustment
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
