import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1, 1, 0, 1)
    }
}));

export default function Loading() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card square>
                <CardContent>
                    <Skeleton variant="rect" animation="wave" width="100%" height={300} />
                </CardContent>
            </Card>
        </div>
    )
}