import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Skeleton from '@material-ui/lab/Skeleton';

export default function Loading() {
  return (
    [...Array(3)].map((items, index) =>
      <Card key={index} square elevation={0}>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" width="60%" height={25} />
            </Grid>
            <Grid item xs={6} >
              <Skeleton variant="text" animation="wave" width="100%" />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Skeleton variant="text" animation="wave" width="100%" />
            <Skeleton variant="text" animation="wave" width="100%" />
            <Skeleton variant="text" animation="wave" width="100%" />
          </Grid>
        </CardContent>
        {index < 2 && <Divider />}
      </Card >
    )
  )
}
