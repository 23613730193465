const now = new Date();
const events = [
    {
        id: 1,
        color: 'red',
        start: new Date(new Date().setHours(new Date().getHours() - 3)),
        end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
    {
        id: 2,
        start: now,
        end: now,
    },
    {
        id: 3,
        start: now,
        end: now,
    },
    {
        id: 4,
        start: now,
        end: now,
    },
    {
        id: 5,
        start: new Date(2020, 1, 7, 8, 0, 0),
        end: new Date(2020, 1, 7, 10, 0, 0),
    },
    {
        id: 6,
        allDay: true,
        start: new Date(2020, 1, 20, 16, 0, 0),
        end: new Date(2020, 1, 24, 20, 0, 0),
    },
]

export default events