import React, { Suspense } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Typography from "@material-ui/core/Typography";
import Headbar from "../headbar";
import Loading from '../loading/custom-profile';
import TextField from '@material-ui/core/TextField';

const LazyCustomerProfile = React.lazy(() =>
  new Promise((resolve, reject) =>
    setTimeout(() => resolve(
      import("./index").then(module => ({ default: module.CustomerProfile }))
    ), 5000)
  )
);

export function CustomerProfile() {
  return (
    <div className="customer-profile">
      <Typography variant="h5" component="h3">
        คุณจิตดี คุณธรรม
      </Typography>
      <div className="pt-2 align-items-center">
        <div className="d-flex align-items-center">
          <img className="img-fluid" src="/assets/images/icon/true_blackcard@3x.png" width="26px" alt="" />
          <Typography className="p-l-10" variant="body2" component="p">
            [Black.B] 88991558890499327
          </Typography>
        </div>
        <div className="pt-3">
          <Grid container direction="row">
            <Grid item xs={5}>
              <Typography variant="body2" color="textSecondary">Birth Date:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2">19 May, 1994</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={5}>
              <Typography variant="body2" color="textSecondary">ID Card:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2">1134500098129</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={5}>
              <Typography variant="body2" color="textSecondary">Customer Type:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2">VIP</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Note :"
                multiline
                rows="4"
                defaultValue="Hello VIP"
                variant="outlined"
                className="mt-3 w-100"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default function Index(props) {
  const { handleAddTab } = props
  return (
    <div>
      <Card square>
        <Headbar
          icon={<PermIdentityIcon />}
          title="Customer Profile"
          button
          onClick={handleAddTab("คุณจิตดี คุณธรรม")} />
        <CardContent>
          <Suspense fallback={<Loading />}>
            <LazyCustomerProfile />
          </Suspense>
        </CardContent>
      </Card>
    </div>
  )
}