import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import SingleSummary from "./singlesummary";
import IconButton from "@material-ui/core/IconButton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function SingleContent(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTimeout(() => {
      setSlidebar(false);
    }, 500);
  };

  const [slidebar, setSlidebar] = React.useState(false);

  const handleSlidebar = () => {
    setSlidebar(prev => !prev);
  };

  const handleClose = () => {
    setSlidebar(false);
  };

  return (
    <div>
      <Card className="single-content-wrap" square elevation={0}>
        <div className="single-content-menu">
          <AppBar
            className={`nav-slide ${slidebar ? "active" : ""}`}
            position="static"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              orientation="vertical"
            >
              <Tab label="Main Summary" {...a11yProps(0)} />
              <Tab label="Final Change" {...a11yProps(1)} />
              <Tab label="Collection Step" {...a11yProps(2)} />
              <Tab label="Balance by Customer" {...a11yProps(3)} />
              <Tab label="Status History" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <div className="nav-slide-dim" onClick={handleClose}></div>
          <IconButton
            color="inherit"
            aria-label="Menu"
            className="circle-border"
            onClick={handleSlidebar}
          >
            <MenuIcon />
          </IconButton>
        </div>

        <TabPanel value={value} index={0}>
          <SingleSummary />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Final Change Content
        </TabPanel>
        <TabPanel value={value} index={2}>
          Collection Step Content
        </TabPanel>
        <TabPanel value={value} index={3}>
          Balance by Customer Content
        </TabPanel>
        <TabPanel value={value} index={4}>
          Status History Content
        </TabPanel>
      </Card>
    </div>
  );
}
