import React from 'react'
import Grid from "@material-ui/core/Grid";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  grid: {
    paddingLeft: theme.spacing(2),
    borderRight: '1px solid #dee2e6',
    '&:last-child': {
      borderRight: 0,
    }
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div>
      <Skeleton variant="rect" animation="wave" width="70%" height={150} className="ml-auto mr-auto mt-5" />
      <Skeleton variant="text" animation="wave" width="50%" height={26} className="ml-auto mr-auto my-3" />
      <Grid container className="pb-2">
        {[...Array(3)].map((items, index) =>
          <Grid item xs key={index} className={'d-flex align-items-center flex-column pr-3 ' + classes.grid}>
            <Skeleton variant="text" animation="wave" width="50%" height={26} className="ml-auto mr-auto pb-1" />
            <Grid container>
              <Grid item xs className="overflow-hidden">
                <Skeleton variant="text" animation="wave" width="80%" />
              </Grid>
              <Grid item xs className="flex-0-0-a">
                <Skeleton variant="text" animation="wave" width={60} className="ml-auto" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs className="overflow-hidden">
                <Skeleton variant="text" animation="wave" width="80%" />
              </Grid>
              <Grid item xs className="flex-0-0-a">
                <Skeleton variant="text" animation="wave" width={60} className="ml-auto" />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  )
}